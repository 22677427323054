<template>
  <div class="w-full md:w-[230px]">
    <el-menu
        :default-active="activeIndex"
        class="xty-menu md:!h-[44px] md:justify-around !border-b-0"
        :menu-trigger="isMobile ? 'click' : 'hover'"
        mode="horizontal"
        :ellipsis-icon="MoreFilled"
        active-text-color="#FF5005"
        text-color="#000000e0"
        style="--el-menu-hover-bg-color: #FF500530; --el-menu-hover-text-color: #FF5005; --el-menu-bg-color: transparent;"
        @select="handleSelect"
    >
      <template :key="item.index" v-for="item in processedItems">
        <template v-if="item.subs">
          <el-sub-menu :index="item.index">
            <template #title>{{ item.title }}</template>
            <template :key="sub.index" v-for="sub in item.subs">
              <!-- 三级菜单 -->
              <template v-if="sub.subs">
                <el-sub-menu :index="sub.index">
                  <template #title>{{ sub.title }}</template>
                  <template :key="ssub.index" v-for="ssub in sub.subs">
                    <el-menu-item :index="ssub.index" :disabled="ssub.nonclickable" style="--el-menu-hover-bg-color: #FF500530; --el-menu-hover-text-color: #FF5005;">
                      {{ ssub.title }}
                    </el-menu-item>
                  </template>
                </el-sub-menu>
              </template>
              <!-- 二级菜单 -->
              <template v-else>
                <el-menu-item :index="sub.index" :disabled="sub.nonclickable" style="--el-menu-hover-bg-color: #FF500530; --el-menu-hover-text-color: #FF5005;">
                  {{ sub.title }}
                </el-menu-item>
              </template>
            </template>
          </el-sub-menu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :disabled="item.nonclickable">
            {{ item.title }}
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { MoreFilled } from '@element-plus/icons-vue'

const props = defineProps({
  menu: {
    type: Object,
    required: true
  },
  userRoles: {
    type: Array,
    default: () => []
  }
})

const isMobile = ref(false)
const activeIndex = ref('/')

// 处理菜单项，添加供需对接相关功能
const processedItems = computed(() => {
  const hasEventAccess = props.userRoles && props.userRoles.some(role =>
      ['event_holder', 'event_admin', 'admin'].includes(role)
  )
  console.log(props.userRoles)
  console.log(hasEventAccess)

  let menuItems = Array.isArray(props.menu) ? [...props.menu] : [...Object.values(props.menu)]

  if (hasEventAccess) {
    // 创建供需对接菜单项
    const eventMenu = {
      title: '供需对接深度行专区',
      index: '#',
      subs: [
        {
          title: '活动发布',
          index: '/events'
        },
        {
          title: '活动总结',
          index: '/event_conclusions'
        }
      ]
    }
    menuItems.push(eventMenu)
  }

  return menuItems
})

// 处理菜单选择
const handleSelect = (index, indexPath) => {
  const currentDomain = window.location.hostname
  const tempLink = document.createElement('a')
  tempLink.href = index

  const targetDomain = tempLink.hostname

  if (targetDomain === currentDomain) {
    window.location.href = index
  } else {
    window.open(index, '_blank')
  }
}

// 解析当前活动的菜单项
const parseActiveIndex = () => {
  activeIndex.value = window.location.pathname
}

// 监听窗口大小变化
const handleResize = () => {
  isMobile.value = window.innerWidth < 768
}

onMounted(() => {
  parseActiveIndex()
  handleResize()
  window.addEventListener('resize', handleResize)
})

// 在组件销毁时移除事件监听
onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>