import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "max-w-[1440px] m-auto px-4 py-6" }
const _hoisted_2 = { class: "mb-6 flex items-center" }
const _hoisted_3 = {
  href: "/events",
  class: "inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200"
}
const _hoisted_4 = {
  key: 0,
  class: "bg-white rounded-lg shadow-md p-8"
}
const _hoisted_5 = { class: "flex justify-between items-start mb-6" }
const _hoisted_6 = { class: "text-3xl font-bold" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center gap-3"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "grid grid-cols-1 md:grid-cols-2 gap-8 mb-8" }
const _hoisted_10 = { class: "space-y-4" }
const _hoisted_11 = { class: "flex items-center gap-2 text-gray-600" }
const _hoisted_12 = { class: "flex items-center gap-2 text-gray-600" }
const _hoisted_13 = { class: "flex items-center gap-2 text-gray-600" }
const _hoisted_14 = { class: "space-y-4" }
const _hoisted_15 = { class: "flex items-center gap-2" }
const _hoisted_16 = { class: "space-y-6" }
const _hoisted_17 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_18 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_19 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_20 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_25 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_26 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_27 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_28 = {
  key: 0,
  class: "mt-8 border border-orange-200 rounded-lg overflow-hidden"
}
const _hoisted_29 = { class: "bg-orange-50 px-6 py-4 border-b border-orange-200" }
const _hoisted_30 = { class: "flex items-center gap-2" }
const _hoisted_31 = { class: "px-6 py-4 bg-white" }
const _hoisted_32 = {
  key: 0,
  class: "flex justify-between items-start"
}
const _hoisted_33 = {
  key: 0,
  class: "text-[16px] text-[#B54708] mb-4"
}
const _hoisted_34 = {
  key: 1,
  class: "text-[16px] text-gray-500 italic mb-4"
}
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { class: "flex gap-2" }
const _hoisted_37 = {
  key: 1,
  class: "text-[16px] text-[#B54708]"
}
const _hoisted_38 = {
  key: 1,
  class: "mt-8 pt-6 border-t border-gray-200"
}
const _hoisted_39 = { class: "flex justify-end gap-4" }
const _hoisted_40 = {
  key: 1,
  class: "flex justify-center items-center h-64"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tag = _resolveComponent("el-tag")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_empty = _resolveComponent("el-empty")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_el_icon, { class: "mr-1" }, {
          default: _withCtx(() => [
            _createVNode($setup["ArrowLeft"])
          ]),
          _: 1 /* STABLE */
        }),
        _cache[1] || (_cache[1] = _createTextVNode(" 返回活动列表 "))
      ])
    ]),
    ($props.event)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h1", _hoisted_6, _toDisplayString($props.event.title), 1 /* TEXT */),
            ($setup.canEdit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("a", {
                    href: '/events/' + $props.event.id + '/edit',
                    class: "inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  }, [
                    _createVNode(_component_el_icon, { class: "mr-1.5" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["Edit"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _cache[2] || (_cache[2] = _createTextVNode(" 编辑活动 "))
                  ], 8 /* PROPS */, _hoisted_8),
                  _createVNode(_component_el_button, {
                    type: "danger",
                    onClick: $setup.handleDelete,
                    class: "!h-[36px]"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: "mr-1.5" }, {
                        default: _withCtx(() => [
                          _createVNode($setup["Delete"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _cache[3] || (_cache[3] = _createTextVNode(" 删除活动 "))
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode($setup["Calendar"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createElementVNode("span", null, "活动日期：" + _toDisplayString($setup.formatDate($props.event.event_date)), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode($setup["Location"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createElementVNode("span", null, "活动地点：" + _toDisplayString($props.event.event_location), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode($setup["OfficeBuilding"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createElementVNode("span", null, "主办单位：" + _toDisplayString($props.event.event_leader), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_el_tag, {
                  type: $setup.getStatusType($props.event.status)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.getStatusText($props.event.status)), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["type"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", null, [
              _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "活动主题", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_17, _toDisplayString($props.event.description), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "承办单位", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_18, _toDisplayString($props.event.main_holder), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "协办单位", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_19, _toDisplayString($props.event.sup_holder), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "活动议程", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_20, _toDisplayString($props.event.procedure), 1 /* TEXT */)
            ]),
            _createCommentVNode(" 附件 "),
            ($props.event.attachments?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "相关附件", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.event.attachments, (attachment) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: attachment.url,
                        class: "mb-2 last:mb-0"
                      }, [
                        _createElementVNode("a", {
                          href: attachment.url,
                          target: "_blank",
                          class: "inline-flex items-center text-blue-600 hover:text-blue-800"
                        }, [
                          _createVNode(_component_el_icon, { class: "mr-1" }, {
                            default: _withCtx(() => [
                              _createVNode($setup["Document"])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createElementVNode("span", null, _toDisplayString(attachment.original_filename), 1 /* TEXT */)
                        ], 8 /* PROPS */, _hoisted_23)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", null, [
                _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "需求方企业", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_25, _toDisplayString($props.event.demand_participants), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, [
                _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "供应方企业", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_26, _toDisplayString($props.event.supply_participants), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, [
                _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "媒体参与", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_27, _toDisplayString($props.event.media_participants), 1 /* TEXT */)
              ])
            ])
          ]),
          _createCommentVNode(" 管理员留言区域 "),
          ($props.isAdmin || $props.event.admin_message)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _createVNode(_component_el_icon, { class: "text-[20px] text-[#F79009]" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["Warning"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "text-[18px] font-medium text-[#B54708]" }, "管理员留言", -1 /* HOISTED */))
                  ])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  ($props.isAdmin)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (!$setup.isEditing)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                              ($props.event.admin_message)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_33, _toDisplayString($props.event.admin_message), 1 /* TEXT */))
                                : (_openBlock(), _createElementBlock("p", _hoisted_34, "暂无留言")),
                              _createVNode(_component_el_button, {
                                type: "primary",
                                onClick: $setup.startEditing,
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($props.event.admin_message ? '编辑留言' : '添加留言'), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_35, [
                              _createVNode(_component_el_input, {
                                modelValue: $setup.adminMessage,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.adminMessage) = $event)),
                                type: "textarea",
                                rows: 3,
                                placeholder: "请输入留言内容",
                                class: "mb-4"
                              }, null, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_36, [
                                _createVNode(_component_el_button, { onClick: $setup.cancelEditing }, {
                                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                                    _createTextVNode("取消")
                                  ])),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_el_button, {
                                  type: "primary",
                                  onClick: $setup.handleUpdateMessage
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString($props.event.admin_message ? '更新留言' : '提交留言'), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ]))
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock("p", _hoisted_37, _toDisplayString($props.event.admin_message), 1 /* TEXT */))
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" 管理员审核按钮 "),
          ($setup.showAuditButtons)
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createVNode(_component_el_button, {
                    type: "danger",
                    class: "!h-[48px] !text-[16px]",
                    onClick: $setup.handleReject
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" 拒绝方案 ")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "!text-center !h-[48px] !text-[16px] !text-white !bg-[#3250BD] !rounded-[8px] !border-[1px] !border-[#3250BD]",
                    onClick: $setup.handleApprove
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode(" 同意方案 ")
                    ])),
                    _: 1 /* STABLE */
                  })
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_40, [
          _createVNode(_component_el_empty, { description: "未找到活动信息" })
        ]))
  ]))
}