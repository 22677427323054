import request from './request'

/** 活动 开始 */
// 获取活动列表
export function eventList(query) {
  return request({
    url: '/internal_api/events',
    method: 'get',
    params: query
  })
}

// 获取活动详情
export function eventDetail(id) {
  return request({
    url: `/internal_api/events/${id}`,
    method: 'get'
  })
}

// 创建活动
export function eventCreate(data) {
  return request({
    url: '/internal_api/events',
    method: 'post',
    data
  })
}

// 更新活动
export function eventUpdate(id, data) {
  return request({
    url: `/internal_api/events/${id}`,
    method: 'put',
    data
  })
}

// 删除活动
export function eventDelete(id) {
  return request({
    url: `/internal_api/events/${id}`,
    method: 'delete'
  })
}

// 审核通过
export function eventApprove(id) {
  return request({
    url: `/internal_api/events/${id}/approve`,
    method: 'put'
  })
}

// 审核拒绝
export function eventReject(id, rejectReason) {
  return request({
    url: `/internal_api/events/${id}/reject`,
    method: 'put',
    data: {
      admin_message: rejectReason
    }
  })
}

export const eventUpdateMessage = (id, message) => {
  return request({
    url: `/internal_api/events/${id}/update_message`,
    method: 'put',
    data: {
      admin_message: message
    }
  })
}

// 导出CSV
export function exportEventCSV(params) {
  return request({
    url: '/internal_api/events/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
/** 活动 结束 */
