<template>
  <div class="max-w-[1440px] m-auto px-4 py-6">
    <div class="mb-6 flex items-center">
      <a href="/events" class="inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200">
        <el-icon class="mr-1"><ArrowLeft /></el-icon>
        返回活动列表
      </a>
    </div>
    <div v-if="event" class="bg-white rounded-lg shadow-md p-8">
      <div class="flex justify-between items-start mb-6">
        <h1 class="text-3xl font-bold">{{ event.title }}</h1>
        <div v-if="canEdit" class="flex items-center gap-3">
          <a
              :href="'/events/' + event.id + '/edit'"
              class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <el-icon class="mr-1.5"><Edit /></el-icon>
            编辑活动
          </a>
          <el-button
              type="danger"
              @click="handleDelete"
              class="!h-[36px]"
          >
            <el-icon class="mr-1.5"><Delete /></el-icon>
            删除活动
          </el-button>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div class="space-y-4">
          <div class="flex items-center gap-2 text-gray-600">
            <el-icon><Calendar /></el-icon>
            <span>活动日期：{{ formatDate(event.event_date) }}</span>
          </div>
          <div class="flex items-center gap-2 text-gray-600">
            <el-icon><Location /></el-icon>
            <span>活动地点：{{ event.event_location }}</span>
          </div>
          <div class="flex items-center gap-2 text-gray-600">
            <el-icon><OfficeBuilding /></el-icon>
            <span>主办单位：{{ event.event_leader }}</span>
          </div>
        </div>

        <div class="space-y-4">
          <div class="flex items-center gap-2">
            <el-tag :type="getStatusType(event.status)">{{ getStatusText(event.status) }}</el-tag>
          </div>
        </div>
      </div>

      <div class="space-y-6">
        <div>
          <h2 class="text-xl font-bold mb-3">活动主题</h2>
          <div class="bg-gray-50 rounded-lg p-4">
            {{ event.description }}
          </div>
        </div>

        <div>
          <h2 class="text-xl font-bold mb-3">承办单位</h2>
          <div class="bg-gray-50 rounded-lg p-4">
            {{ event.main_holder }}
          </div>
        </div>

        <div>
          <h2 class="text-xl font-bold mb-3">协办单位</h2>
          <div class="bg-gray-50 rounded-lg p-4">
            {{ event.sup_holder }}
          </div>
        </div>

        <div>
          <h2 class="text-xl font-bold mb-3">活动议程</h2>
          <div class="bg-gray-50 rounded-lg p-4">
            {{ event.procedure }}
          </div>
        </div>

        <!-- 附件 -->
        <div v-if="event.attachments?.length">
          <h2 class="text-xl font-bold mb-3">相关附件</h2>
          <div class="bg-gray-50 rounded-lg p-4">
            <div v-for="attachment in event.attachments" :key="attachment.url" class="mb-2 last:mb-0">
              <a
                  :href="attachment.url"
                  target="_blank"
                  class="inline-flex items-center text-blue-600 hover:text-blue-800"
              >
                <el-icon class="mr-1"><Document /></el-icon>
                <span>{{ attachment.original_filename }}</span>
              </a>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <h2 class="text-xl font-bold mb-3">需求方企业</h2>
            <div class="bg-gray-50 rounded-lg p-4">
              {{ event.demand_participants }}
            </div>
          </div>

          <div>
            <h2 class="text-xl font-bold mb-3">供应方企业</h2>
            <div class="bg-gray-50 rounded-lg p-4">
              {{ event.supply_participants }}
            </div>
          </div>

          <div>
            <h2 class="text-xl font-bold mb-3">媒体参与</h2>
            <div class="bg-gray-50 rounded-lg p-4">
              {{ event.media_participants }}
            </div>
          </div>
        </div>
      </div>

      <!-- 管理员留言区域 -->
      <div v-if="isAdmin || event.admin_message" class="mt-8 border border-orange-200 rounded-lg overflow-hidden">
        <div class="bg-orange-50 px-6 py-4 border-b border-orange-200">
          <div class="flex items-center gap-2">
            <el-icon class="text-[20px] text-[#F79009]"><Warning /></el-icon>
            <h3 class="text-[18px] font-medium text-[#B54708]">管理员留言</h3>
          </div>
        </div>
        <div class="px-6 py-4 bg-white">
          <template v-if="isAdmin">
            <div v-if="!isEditing" class="flex justify-between items-start">
              <p v-if="event.admin_message" class="text-[16px] text-[#B54708] mb-4">{{ event.admin_message }}</p>
              <p v-else class="text-[16px] text-gray-500 italic mb-4">暂无留言</p>
              <el-button type="primary" @click="startEditing" size="small">
                {{ event.admin_message ? '编辑留言' : '添加留言' }}
              </el-button>
            </div>
            <div v-else>
              <el-input
                  v-model="adminMessage"
                  type="textarea"
                  :rows="3"
                  placeholder="请输入留言内容"
                  class="mb-4"
              />
              <div class="flex gap-2">
                <el-button @click="cancelEditing">取消</el-button>
                <el-button type="primary" @click="handleUpdateMessage">
                  {{ event.admin_message ? '更新留言' : '提交留言' }}
                </el-button>
              </div>
            </div>
          </template>
          <p v-else class="text-[16px] text-[#B54708]">{{ event.admin_message }}</p>
        </div>
      </div>

      <!-- 管理员审核按钮 -->
      <div v-if="showAuditButtons" class="mt-8 pt-6 border-t border-gray-200">
        <div class="flex justify-end gap-4">
          <el-button
              type="danger"
              class="!h-[48px] !text-[16px]"
              @click="handleReject"
          >
            拒绝方案
          </el-button>
          <el-button
              type="primary"
              class="!text-center !h-[48px] !text-[16px] !text-white !bg-[#3250BD] !rounded-[8px] !border-[1px] !border-[#3250BD]"
              @click="handleApprove"
          >
            同意方案
          </el-button>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center items-center h-64">
      <el-empty description="未找到活动信息" />
    </div>
  </div>
</template>

<script setup>
import { Calendar, Location, OfficeBuilding, ArrowLeft, Edit, Warning, Document, Delete } from '@element-plus/icons-vue'
import { computed, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { eventApprove, eventReject, eventUpdateMessage, eventDelete } from '@api/csrf/event'

const props = defineProps({
  event: {
    type: Object,
    required: true
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isEventHolder:{
    type: Boolean,
    default: false
  },
  currentUserId: {
    type: [Number, String],
    required: true
  }
})

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

const getStatusType = (status) => {
  const types = {
    draft: '',
    submitted: 'warning',
    approved: 'success',
    rejected: 'danger'
  }
  return types[status] || ''
}

const getStatusText = (status) => {
  const texts = {
    draft: '草稿',
    submitted: '审核中',
    approved: '已通过',
    rejected: '已拒绝'
  }
  return texts[status] || status
}

// 计算属性：是否可以编辑
const canEdit = computed(() => {
  return props.isEventHolder && props.event.user_id === props.currentUserId && ['draft', 'rejected'].includes(props.event.status)
})

// 计算属性：是否显示审核按钮
const showAuditButtons = computed(() => {
  return props.isAdmin && props.event.status === 'submitted'
})

// 审核通过
const handleApprove = async () => {
  try {
    await ElMessageBox.confirm('确认方案信息无误，通过审核', '通过确认', {
      confirmButtonText: '确定通过',
      cancelButtonText: '取消',
      type: 'warning'
    })

    const response = await eventApprove(props.event.id)
    ElMessage({
      message: '以批准该活动方案',
      type: 'success',
      duration: 500,
      onClose: () => {
        window.location.reload()
      }
    })
  } catch (error) {
    ElMessage({
      message: error.response?.data?.error || '操作失败，请重试',
      type: 'error'
    })
  }
}

// 审核拒绝
const handleReject = async () => {
  try {
    const {value: rejectReason} = await ElMessageBox.prompt('', '拒绝原因', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPlaceholder: '请输入拒绝原因',
      inputValue: props.event.admin_message || '',
      inputValidator: (value) => {
        if (!value) {
          return '拒绝原因不能为空'
        }
        return true
      }
    })

    if (rejectReason) {
      await eventReject(props.event.id, rejectReason)
      ElMessage({
        message: '已拒绝该方案',
        type: 'success',
        duration: 500,
        onClose: () => {
          window.location.reload()
        }
      })
    }
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage({
        message: error.response?.data?.error || '操作失败，请重试',
        type: 'error'
      })
    }
  }
}

const adminMessage = ref(props.event.admin_message || '')
const isEditing = ref(false)

const startEditing = () => {
  adminMessage.value = props.event.admin_message || ''
  isEditing.value = true
}

const cancelEditing = () => {
  adminMessage.value = props.event.admin_message || ''
  isEditing.value = false
}

const handleUpdateMessage = async () => {
  try {
    await eventUpdateMessage(props.event.id, adminMessage.value)
    ElMessage({
      message: adminMessage.value ? "留言已更新！" : "留言已添加！",
      type: 'success',
      duration: 500,
      onClose: () => {
        window.location.reload()
      }
    })
  } catch (error) {
    console.error('更新留言失败:', error)
    ElMessage.error('更新留言失败')
  }
}

const handleDelete = async () => {
  try {
    await ElMessageBox.confirm('确定要删除这个活动吗？此操作不可恢复。', '删除确认', {
      confirmButtonText: '确定删除',
      cancelButtonText: '取消',
      type: 'warning'
    })

    await eventDelete(props.event.id)
    ElMessage({
      message: '活动已删除',
      type: 'success',
      duration: 500,
      onClose: () => {
        window.location.href = '/events'
      }
    })
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error(error.message || '删除失败')
    }
  }
}
</script> 