import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useCookies } from "vue3-cookies";
import { user_sessions as sessions_en } from "../../../../config/locales/en_js/user_sessions.js"
import { user_sessions as sessions_zh } from "../../../../config/locales/zh_js/user_sessions.js"


const { cookies } = useCookies();
// 创建实例
const service = axios.create({
  baseURL: JINGZHI_SERVER,
  timeout: 30000 // 请求超时
})

const popupReloginDialog = () => {
  const sessionLocale = cookies.get('locale') === 'en' ? sessions_en : sessions_zh
  ElMessageBox.confirm(sessionLocale.expiredDesc, sessionLocale.expiredTitle, {
    'show-close': false,
    confirmButtonText: sessionLocale.reLogin,
    cancelButtonText: sessionLocale.cancelLogin,
  })
  .then(() => {
    window.location.href = "/logout?redirect_to=/login"
  })
  .catch(() => {
    window.location.href = "/logout"
  })
}


// 请求拦截器


service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // 请求错误，可在此进行统一错误处理
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    response = error.response
    const message = response.data.msg
    if (response.status === 401) {
      popupReloginDialog()
    } else if (response.status === 404) {
      window.location.href = "/errors/not-found"
    } else {
      ElMessage({
        message: data.message,
        type: 'error'
      })
      return Promise.reject(response)
    }
  }
)

export default service
