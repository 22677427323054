<template>
  <nav class="navigation-tabs">
    <ul class="flex flex-wrap justify-center gap-2 p-4">
      <li v-for="(group, idx) in groupedItems" :key="idx">
        <button
            class="tab-button relative flex items-center h-12 px-4 rounded-full transition-all duration-300 ease-out overflow-hidden"
            :class="{
            'bg-gradient-to-r from-blue-500 to-indigo-600 text-white': isActiveOrHovered(group.appclass),
            'bg-white text-gray-700 hover:bg-gray-100': !isActiveOrHovered(group.appclass)
          }"
            @click="$emit('tab-click', group.appclass)"
            @mouseenter="handleMouseEnter(group.appclass)"
            @mouseleave="handleMouseLeave"
        >
          <div class="flex items-center justify-center w-8 h-8">
            <component :is="getIcon(group.appclass)" class="w-5 h-5 transition-transform duration-300" :class="{'scale-110': isActiveOrHovered(group.appclass)}" />
          </div>

          <div class="tab-content ml-2 flex items-center transition-all duration-300 ease-out"
               :class="{'opacity-100 max-w-[200px]': isActiveOrHovered(group.appclass), 'opacity-0 max-w-0': !isActiveOrHovered(group.appclass)}">
            <span class="whitespace-nowrap text-sm font-medium">
              {{ group.appclass }}
            </span>
            <span v-if="group.items.length > 0"
                  class="ml-2 text-xs px-1.5 py-0.5 rounded-full bg-white/20 min-w-[20px] text-center">
              {{ group.items.length }}
            </span>
          </div>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Users, Monitor, PenTool, MessageCircle, Music, Video, Code, Sparkles } from 'lucide-vue-next'

const props = defineProps({
  groupedItems: {
    type: Array,
    required: true
  },
  currentSection: {
    type: String,
    required: true
  },
  isSearching: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['tab-click'])

const hoveredSection = ref(null)
const hoverTimeout = ref(null)

const getIcon = (appclass) => {
  switch (appclass) {
    case 'AI社区':
      return Users
    case 'AI办公':
      return Monitor
    case 'AI写作':
      return PenTool
    case 'AI对话':
      return MessageCircle
    case 'AI音频':
      return Music
    case 'AI视频':
      return Video
    case 'AI编程':
      return Code
    default:
      return Sparkles
  }
}

const isActiveOrHovered = computed(() => (appclass) =>
    (props.currentSection === appclass && !props.isSearching) || hoveredSection.value === appclass
)

const handleMouseEnter = (appclass) => {
  clearTimeout(hoverTimeout.value)
  hoverTimeout.value = setTimeout(() => {
    hoveredSection.value = appclass
  }, 50) // Small delay to prevent flickering
}

const handleMouseLeave = () => {
  clearTimeout(hoverTimeout.value)
  hoverTimeout.value = setTimeout(() => {
    hoveredSection.value = null
  }, 100) // Slightly longer delay on leave for smoother transition
}
</script>

<style scoped>
.navigation-tabs {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5rem;
}

.tab-button {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.tab-button:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.tab-content {
  transition: opacity 300ms ease-out, max-width 300ms ease-out;
}
</style>