import request from './request'

// 创建活动总结
export function createEventConclusion(data) {
  return request({
    url: '/internal_api/event_conclusions',
    method: 'post',
    data
  })
}

// 更新活动总结
export function updateEventConclusion(id, data) {
  return request({
    url: `/internal_api/event_conclusions/${id}`,
    method: 'put',
    data
  })
}

// 删除活动总结
export function deleteEventConclusion(id) {
  return request({
    url: `/internal_api/event_conclusions/${id}`,
    method: 'delete'
  })
}

// 审核通过
export function eventConclusionApprove(id) {
  return request({
    url: `/internal_api/event_conclusions/${id}/approve`,
    method: 'put'
  })
}

// 审核拒绝
export function eventConclusionReject(id, data) {
  return request({
    url: `/internal_api/event_conclusions/${id}/reject`,
    method: 'put',
    data
  })
}

// 更新管理员留言
export function eventConclusionUpdateMessage(id, data) {
  return request({
    url: `/internal_api/event_conclusions/${id}/update_message`,
    method: 'put',
    data
  })
}

// 导出CSV
export function exportEventConclusionCSV(params) {
  return request({
    url: '/internal_api/event_conclusions/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
} 