<template>
  <div class="flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0">
    <div class="pt-[32px] w-full xl:pr-[20px] mlg:px-[20px]">
      <div v-if="isEventHolder" class="mb-8">
        <div class="flex xl:flex-col justify-between px-[16px] mb-4">
          <h3 class="text-lg font-normal text-[#101828] flex items-center gap-2">
            <span>我的活动方案</span>
          </h3>
          <div class="flex items-center gap-4">
            <el-select
              v-model="userStatusFilter"
              placeholder="状态筛选"
              clearable
              @change="handleUserStatusChange"
              class="!w-[120px]"
            >
              <el-option
                v-for="item in userStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <a href="/events/new" class="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <el-icon class="mr-1"><Plus /></el-icon>
              新建活动
            </a>
          </div>
        </div>
        <div class="grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-4 mb-4 mt-[16px] xl:pl-[20px] mlg:pl-0">
          <div v-for="event in events" :key="event.id">
            <event-card :event="event" />
          </div>
        </div>
      </div>

      <div v-if="isAdmin">
        <div class="flex xl:flex-col justify-between px-[16px] mb-4">
          <h3 class="text-lg font-normal text-[#101828] flex items-center gap-2">
            <span>活动方案审核</span>
          </h3>
          <div class="flex items-center gap-4">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              @change="handleDateRangeChange"
              class="!w-[360px]"
            />
            <el-select
              v-model="adminStatusFilter"
              placeholder="状态筛选"
              clearable
              @change="handleAdminStatusChange"
              class="!w-[120px]"
            >
              <el-option
                v-for="item in adminStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-button 
              v-if="hasAdminFilter"
              size="default"
              class="!h-[32px]"
              @click="resetAdminFilters"
            >
              清除筛选
            </el-button>
            <el-button 
              type="primary"
              size="default"
              class="!h-[32px]"
              @click="handleExport"
            >
              导出表格
            </el-button>
          </div>
        </div>
        <div class="px-[16px] mb-2">
          <div class="bg-white rounded-lg border border-[#EAECF0] overflow-hidden">
            <div class="grid grid-cols-[3fr,2fr,180px,180px,120px,100px] gap-4 px-6 py-3 bg-gray-50 border-b border-[#EAECF0]">
              <div class="text-sm font-medium text-gray-500">活动名称</div>
              <div class="text-sm font-medium text-gray-500">主办单位</div>
              <div class="text-sm font-medium text-gray-500">活动时间</div>
              <div class="text-sm font-medium text-gray-500">活动地点</div>
              <div class="text-sm font-medium text-gray-500">状态</div>
              <div class="text-sm font-medium text-gray-500 text-right">详情</div>
            </div>
            <div v-for="event in adminEvents" :key="event.id" class="grid grid-cols-[3fr,2fr,180px,180px,120px,100px] gap-4 px-6 py-4 border-b border-[#EAECF0] hover:bg-gray-50">
              <div class="text-sm text-gray-900 truncate">
                <a :href="'/events/' + event.id" class="hover:text-blue-600">{{ event.title }}</a>
              </div>
              <div class="text-sm text-gray-500 truncate">{{ event.event_leader }}</div>
              <div class="text-sm text-gray-500 whitespace-nowrap">{{ formatDate(event.event_date) }}</div>
              <div class="text-sm text-gray-500 truncate">{{ event.event_location }}</div>
              <div>
                <el-tag :type="getStatusType(event.status)" size="small">{{ getStatusText(event.status) }}</el-tag>
              </div>
              <div class="text-right">
                <a :href="'/events/' + event.id" class="text-sm text-gray-500 hover:text-blue-600">查看详情</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import EventCard from './EventCard.vue'
import { Plus } from '@element-plus/icons-vue'
import { ref, computed } from 'vue'
import { exportEventCSV } from '@api/csrf/event'
import { ElMessage } from 'element-plus'

// 用户列表相关
const userStatusFilter = ref('')
const userStatusOptions = [
  { value: 'draft', label: '草稿' },
  { value: 'submitted', label: '审核中' },
  { value: 'approved', label: '已通过' },
  { value: 'rejected', label: '已拒绝' }
]

const handleUserStatusChange = (val) => {
  const searchParams = new URLSearchParams(window.location.search)
  if (val) {
    searchParams.set('status', val)
  } else {
    searchParams.delete('status')
  }
  window.location.href = `/events?${searchParams.toString()}`
}

// 管理员列表相关
const dateRange = ref([])
const adminStatusFilter = ref('')
const adminStatusOptions = [
  { value: 'submitted', label: '审核中' },
  { value: 'approved', label: '已通过' },
  { value: 'rejected', label: '已拒绝' }
]

// 检查管理员视图是否有筛选参数
const hasAdminFilter = computed(() => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.has('start_date') || 
         searchParams.has('end_date') || 
         searchParams.has('status')
})

// 初始化筛选值
const initFilters = () => {
  const searchParams = new URLSearchParams(window.location.search)
  
  // 初始化状态筛选
  if (searchParams.has('status')) {
    const status = searchParams.get('status')
    userStatusFilter.value = status
    adminStatusFilter.value = status
  }

  // 初始化日期范围
  if (searchParams.has('start_date') && searchParams.has('end_date')) {
    dateRange.value = [
      searchParams.get('start_date'),
      searchParams.get('end_date')
    ]
  }
}
initFilters()

const handleAdminStatusChange = (val) => {
  const searchParams = new URLSearchParams(window.location.search)
  if (val) {
    searchParams.set('status', val)
  } else {
    searchParams.delete('status')
  }
  window.location.href = `/events?${searchParams.toString()}`
}

const handleDateRangeChange = (val) => {
  const searchParams = new URLSearchParams(window.location.search)
  if (val) {
    searchParams.set('start_date', val[0])
    searchParams.set('end_date', val[1])
  } else {
    searchParams.delete('start_date')
    searchParams.delete('end_date')
  }
  window.location.href = `/events?${searchParams.toString()}`
}

// 仅重置管理员视图的筛选条件
const resetAdminFilters = () => {
  dateRange.value = null
  adminStatusFilter.value = ''
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete('start_date')
  searchParams.delete('end_date')
  searchParams.delete('status')
  window.location.href = `/events${searchParams.toString() ? '?' + searchParams.toString() : ''}`
}

const props = defineProps({
  events: {
    type: Array,
    default: () => []
  },
  adminEvents: {
    type: Array,
    default: () => []
  },
  isEventHolder: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['dateRangeChange'])

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

const getStatusType = (status) => {
  const types = {
    draft: '',
    submitted: 'warning',
    approved: 'success',
    rejected: 'danger'
  }
  return types[status] || ''
}

const getStatusText = (status) => {
  const texts = {
    draft: '草稿',
    submitted: '审核中',
    approved: '已通过',
    rejected: '已拒绝'
  }
  return texts[status] || status
}

const handleExport = async () => {
  try {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {
      start_date: searchParams.get('start_date') || '',
      end_date: searchParams.get('end_date') || '',
      status: searchParams.get('status') || ''
    }
    
    const response = await exportEventCSV(params)
    
    // 创建Blob对象
    const blob = new Blob([response], { type: 'text/csv;charset=UTF-8' })
    
    // 创建下载链接
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    
    // 构建文件名
    let filename = '活动方案'
    if (params.start_date && params.end_date) {
      filename += `(${params.start_date}至${params.end_date})`
    }
    if (params.status) {
      const statusText = adminStatusOptions.find(option => option.value === params.status)?.label || '全部'
      filename += `_${statusText}`
    }
    filename += '.csv'
    link.download = filename
    
    // 触发下载
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href)
  } catch (error) {
    console.error('导出失败:', error)
    ElMessage.error('导出失败，请重试')
  }
}
</script>