import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[1440px] m-auto px-4 py-6" }
const _hoisted_2 = { class: "mb-6 flex items-center" }
const _hoisted_3 = {
  href: "/event_conclusions",
  class: "inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200"
}
const _hoisted_4 = { class: "flex items-center justify-center mb-[100px] md:mb-[50px] mt-[50px] lg:mt-[50px]" }
const _hoisted_5 = { class: "flex flex-col bg-white w-[766px] lg:w-[566px] md:w-[90%] h-auto border rounded-lg p-[36px] border-[#DCDFE6] shadow-xl" }
const _hoisted_6 = { class: "text-2xl font-bold mb-8 text-center" }
const _hoisted_7 = { class: "mb-8" }
const _hoisted_8 = { class: "text-[14px] text-[#606266] mb-2 flex items-center" }
const _hoisted_9 = { class: "flex items-center justify-between p-4 bg-[#F8F9FA] rounded-lg border border-[#E4E7ED]" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "form-section" }
const _hoisted_12 = { class: "form-section" }
const _hoisted_13 = { class: "form-section" }
const _hoisted_14 = { class: "form-section" }
const _hoisted_15 = { class: "form-section" }
const _hoisted_16 = { class: "form-section" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_el_icon, { class: "mr-1" }, {
          default: _withCtx(() => [
            _createVNode($setup["ArrowLeft"])
          ]),
          _: 1 /* STABLE */
        }),
        _cache[8] || (_cache[8] = _createTextVNode(" 返回总结列表 "))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", _hoisted_6, _toDisplayString($setup.isEdit ? '编辑活动总结' : '新建活动总结'), 1 /* TEXT */),
        _createCommentVNode(" 模板下载 "),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_icon, { class: "mr-1 text-[#E6A23C]" }, {
              default: _withCtx(() => [
                _createVNode($setup["Warning"])
              ]),
              _: 1 /* STABLE */
            }),
            _cache[9] || (_cache[9] = _createTextVNode(" 请下载模板，并参考模板提示，如实填写相关信息 "))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_icon, { class: "text-[20px] text-[#3250BD] mr-2" }, {
                default: _withCtx(() => [
                  _createVNode($setup["Document"])
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("span", { class: "text-[14px] text-[#1D2129]" }, _toDisplayString($setup.templateName))
            ]),
            _createVNode(_component_el_button, {
              type: "primary",
              link: "",
              class: "!text-[14px]",
              onClick: $setup.handleTemplateDownload
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "mr-1" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["Download"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _cache[10] || (_cache[10] = _createTextVNode(" 下载模板 "))
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _createVNode(_component_el_form, {
          ref: "formRef",
          model: $setup.formData,
          rules: $setup.rules,
          "label-position": "top"
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" 标题 "),
            _createVNode(_component_el_form_item, {
              label: "标题",
              prop: "title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.formData.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.formData.title) = $event)),
                  placeholder: "请输入标题"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" 活动基本信息 "),
            _createElementVNode("div", _hoisted_11, [
              _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "text-lg font-medium mb-4" }, "活动基本信息", -1 /* HOISTED */)),
              _createVNode(_component_el_form_item, {
                label: "主办单位",
                prop: "event_leader"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.formData.event_leader,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.formData.event_leader) = $event)),
                    placeholder: "请输入主办单位"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, {
                label: "活动时间",
                prop: "event_date"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: $setup.formData.event_date,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.formData.event_date) = $event)),
                    type: "date",
                    placeholder: "选择活动时间",
                    style: {"width":"100%"}
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, {
                label: "活动地点",
                prop: "event_location"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.formData.event_location,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.formData.event_location) = $event)),
                    placeholder: "请输入活动地点"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createCommentVNode(" 背景情况 "),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_el_form_item, {
                label: "背景情况",
                prop: "background"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.formData.background,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.formData.background) = $event)),
                    type: "textarea",
                    rows: 5,
                    placeholder: "请输入背景情况"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createCommentVNode(" 活动情况 "),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_el_form_item, {
                label: "活动情况",
                prop: "conclusion"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.formData.conclusion,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.formData.conclusion) = $event)),
                    type: "textarea",
                    rows: 5,
                    placeholder: "请输入活动情况"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createCommentVNode(" 活动成效 "),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_el_form_item, {
                label: "活动成效",
                prop: "effect"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.formData.effect,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.formData.effect) = $event)),
                    type: "textarea",
                    rows: 5,
                    placeholder: "请输入活动成效"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createCommentVNode(" 下一步计划 "),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_el_form_item, {
                label: "下一步计划",
                prop: "next_step"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.formData.next_step,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.formData.next_step) = $event)),
                    type: "textarea",
                    rows: 5,
                    placeholder: "请输入下一步计划"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createCommentVNode(" 附件上传 "),
            _createElementVNode("div", _hoisted_16, [
              _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "text-lg font-medium mb-4" }, "附件上传", -1 /* HOISTED */)),
              _createVNode(_component_el_form_item, { label: "相关附件" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_upload, {
                    class: "upload-demo",
                    action: '/internal_api/upload',
                    headers: $setup.uploadHeaders,
                    "on-success": $setup.handleUploadSuccess,
                    "on-error": $setup.handleUploadError,
                    "before-upload": $setup.beforeUpload,
                    "file-list": $setup.fileList,
                    "on-remove": $setup.handleFileRemove,
                    multiple: ""
                  }, {
                    tip: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("div", { class: "el-upload__tip text-[#666] mt-2" }, " 支持 doc、docx、pdf、jpg、png 格式文件，单个文件不超过10MB ", -1 /* HOISTED */)
                    ])),
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, { type: "primary" }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode("点击上传")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["file-list"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createVNode(_component_el_form_item, { class: "flex justify-end gap-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  onClick: $setup.handleCancel,
                  class: "!h-[48px] !text-[16px]"
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" 取消 ")
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  class: "!text-center !h-[48px] !text-[16px] !text-white !bg-[#3250BD] !rounded-[8px] !border-[1px] !border-[#3250BD]",
                  onClick: $setup.handleSubmit
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode(" 保存 ")
                  ])),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"])
      ])
    ])
  ]))
}