import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg border border-[#EAECF0] overflow-hidden hover:shadow-md transition-shadow duration-200" }
const _hoisted_2 = { class: "p-6" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "text-lg font-medium text-gray-900 truncate" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "space-y-2" }
const _hoisted_7 = { class: "text-sm text-gray-500 flex items-center" }
const _hoisted_8 = { class: "truncate" }
const _hoisted_9 = { class: "text-sm text-gray-500 flex items-center" }
const _hoisted_10 = { class: "mt-4 pt-3 border-t border-[#EAECF0] flex justify-between items-center" }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_Edit = _resolveComponent("Edit")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, [
          _createElementVNode("a", {
            href: '/event_conclusions/' + $props.conclusion.id,
            class: "hover:text-blue-600"
          }, _toDisplayString($props.conclusion.title), 9 /* TEXT, PROPS */, _hoisted_5)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_icon, { class: "mr-2" }, {
            default: _withCtx(() => [
              _createVNode($setup["OfficeBuilding"])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("span", _hoisted_8, _toDisplayString($props.conclusion.event_leader), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_el_icon, { class: "mr-2" }, {
            default: _withCtx(() => [
              _createVNode($setup["Location"])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("span", null, _toDisplayString($props.conclusion.event_location), 1 /* TEXT */),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mx-2 text-gray-300" }, "|", -1 /* HOISTED */)),
          _createVNode(_component_el_icon, { class: "mr-2" }, {
            default: _withCtx(() => [
              _createVNode($setup["Calendar"])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("span", null, _toDisplayString($setup.formatDate($props.conclusion.event_date)), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("a", {
          href: '/event_conclusions/' + $props.conclusion.id,
          class: "inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200"
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("span", null, "查看详情", -1 /* HOISTED */)),
          _createVNode(_component_el_icon, { class: "ml-1.5 text-[16px]" }, {
            default: _withCtx(() => [
              _createVNode($setup["ArrowRight"])
            ]),
            _: 1 /* STABLE */
          })
        ], 8 /* PROPS */, _hoisted_11),
        _createElementVNode("a", {
          href: '/event_conclusions/' + $props.conclusion.id + '/edit',
          class: "inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200"
        }, [
          _createVNode(_component_el_icon, { class: "mr-1.5 text-[16px]" }, {
            default: _withCtx(() => [
              _createVNode(_component_Edit)
            ]),
            _: 1 /* STABLE */
          }),
          _cache[2] || (_cache[2] = _createElementVNode("span", null, "编辑", -1 /* HOISTED */))
        ], 8 /* PROPS */, _hoisted_12)
      ])
    ])
  ]))
}