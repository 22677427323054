import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-[328px] flex-col bg-gradient-to-l from-[#FAFAFA] to-white border-r border-[#F2F2F2] text-[12px] h-full pb-[20px]"
}
const _hoisted_3 = { class: "pt-[32px] w-full xl:pr-[20px] mlg:px-[20px]" }
const _hoisted_4 = { class: "text-lg font-normal text-[#101828] flex items-center gap-2" }
const _hoisted_5 = { class: "text-gray-400 text-[16px] italic" }
const _hoisted_6 = { class: "xl:mt-[16px]" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-4 mb-4 mt-[16px] xl:pl-[20px] mlg:pl-0"
}
const _hoisted_9 = {
  key: 1,
  class: "flex w-full flex-wrap justify-between md:gap-[12px] gap-[16px] px-[16px] py-[12px] md:p-0"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.repoType != 'code')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!$setup.mobileScreen)
            ? (_openBlock(), _createBlock($setup["TagSidebar"], {
                key: 0,
                taskTags: $setup.taskTags,
                frameworkTags: $setup.frameworkTags,
                languageTags: $setup.languageTags,
                licenseTags: $setup.licenseTags,
                industryTags: $setup.industryTags,
                selectedTag: $setup.props.selectedTag,
                selectedTagType: $setup.props.selectedTagType,
                onResetTags: $setup.resetTags,
                type: $props.repoType
              }, null, 8 /* PROPS */, ["taskTags", "frameworkTags", "languageTags", "licenseTags", "industryTags", "selectedTag", "selectedTagType", "type"]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(`flex xl:flex-col justify-between px-[16px] ${$props.repoType === 'space' ? 'xl:pl-[20px] mlg:pl-0' : ''}`)
      }, [
        _createElementVNode("h3", _hoisted_4, [
          ($props.repoType === 'model')
            ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 0,
                name: "models",
                width: "18",
                height: "18"
              }))
            : _createCommentVNode("v-if", true),
          ($props.repoType === 'dataset')
            ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 1,
                name: "datasets",
                width: "18",
                height: "18"
              }))
            : _createCommentVNode("v-if", true),
          ($props.repoType === 'code')
            ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 2,
                name: "codes",
                width: "18",
                height: "18"
              }))
            : _createCommentVNode("v-if", true),
          ($props.repoType === 'space')
            ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 3,
                name: "spaces",
                width: "18",
                height: "18"
              }))
            : _createCommentVNode("v-if", true),
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_ctx.$t(`${$props.repoType}s.title`)) + " ", 1 /* TEXT */),
            _createElementVNode("span", _hoisted_5, _toDisplayString($setup.totalRepos), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          ($setup.onPremise === 'true')
            ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                modelValue: $setup.sourceSelection,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.sourceSelection) = $event)),
                onChange: $setup.filterChange,
                style: {"width":"150px"},
                class: "mr-4 sm:!w-[122px] sm:mr-1",
                size: "large"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.sourceOptions, (item) => {
                    return _createVNode(_component_el_option, {
                      key: item.value,
                      label: item.label,
                      value: item.value
                    }, null, 8 /* PROPS */, ["label", "value"])
                  }), 64 /* STABLE_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElInput"], {
            modelValue: $setup.nameFilterInput,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.nameFilterInput) = $event)),
            class: "!w-[320px] mr-[16px] xl:!w-[260px] sm:!w-[calc(100%-136px)] sm:mr-1",
            size: "large",
            placeholder: _ctx.$t(`${$props.repoType}s.placeholder`),
            "prefix-icon": $setup.Search,
            onChange: $setup.filterChange
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "prefix-icon"]),
          _createVNode(_component_el_select, {
            modelValue: $setup.sortSelection,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.sortSelection) = $event)),
            onChange: $setup.filterChange,
            style: {"width":"150px"},
            class: "xl:mr-[20px] sm:!w-[110px] sm:mr-0",
            size: "large"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.sortOptions, (item) => {
                return _createVNode(_component_el_option, {
                  key: item.value,
                  label: item.label,
                  value: item.value
                }, null, 8 /* PROPS */, ["label", "value"])
              }), 64 /* STABLE_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]),
          ($props.repoType != 'code')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                ($setup.mobileScreen)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode($setup["MobileTagSidebar"], {
                        taskTags: $setup.taskTags,
                        frameworkTags: $setup.frameworkTags,
                        licenseTags: $setup.licenseTags,
                        industryTags: $setup.industryTags,
                        onResetTags: $setup.resetTags,
                        type: "model"
                      }, null, 8 /* PROPS */, ["taskTags", "frameworkTags", "licenseTags", "industryTags"])
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ])
      ], 2 /* CLASS */),
      ($props.repoType === 'code')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.reposData, (repo) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: repo,
                "repo-type": $props.repoType
              }, null, 8 /* PROPS */, ["repo", "repo-type"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.reposData, (repo) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: repo,
                "repo-type": $props.repoType
              }, null, 8 /* PROPS */, ["repo", "repo-type"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ])),
      _createVNode($setup["CsgPagination"], {
        perPage: $setup.perPage,
        currentPage: $setup.currentPage,
        onCurrentChange: $setup.reloadRepos,
        total: $setup.totalRepos
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "total"])
    ])
  ]))
}