<template>
  <div class="flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0 ">
    <template v-if="repoType != 'code'">
      <div
        class="flex w-[328px] flex-col bg-gradient-to-l from-[#FAFAFA] to-white border-r border-[#F2F2F2] text-[12px] h-full pb-[20px]"
      >
        <TagSidebar
          v-if="!mobileScreen"
          :taskTags="taskTags"
          :frameworkTags="frameworkTags"
          :languageTags="languageTags"
          :licenseTags="licenseTags"
          :industryTags="industryTags"
          :selectedTag="props.selectedTag"
          :selectedTagType="props.selectedTagType"
          @resetTags="resetTags"
          :type="repoType"
        />
      </div>
    </template>
    <div class="pt-[32px] w-full xl:pr-[20px] mlg:px-[20px]">
      <div
        :class="`flex xl:flex-col justify-between px-[16px] ${repoType === 'space' ? 'xl:pl-[20px] mlg:pl-0' : ''}`"
      >
        <h3 class="text-lg font-normal text-[#101828] flex items-center gap-2">
          <SvgIcon
            v-if="repoType === 'model'"
            name="models"
            width="18"
            height="18"
          />
          <SvgIcon
            v-if="repoType === 'dataset'"
            name="datasets"
            width="18"
            height="18"
          />
          <SvgIcon
            v-if="repoType === 'code'"
            name="codes"
            width="18"
            height="18"
          />
          <SvgIcon
            v-if="repoType === 'space'"
            name="spaces"
            width="18"
            height="18"
          />
          <span>
            {{ $t(`${repoType}s.title`) }}
            <span class="text-gray-400 text-[16px] italic">
              {{ totalRepos }}
            </span>
          </span>
        </h3>
        <div class="xl:mt-[16px]">
          <el-select
            v-if="onPremise === 'true'"
            v-model="sourceSelection"
            @change="filterChange"
            style="width: 150px"
            class="mr-4 sm:!w-[122px] sm:mr-1"
            size="large"
          >
            <el-option
              v-for="item in sourceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <ElInput
            v-model="nameFilterInput"
            class="!w-[320px] mr-[16px] xl:!w-[260px] sm:!w-[calc(100%-136px)] sm:mr-1"
            size="large"
            :placeholder="$t(`${repoType}s.placeholder`)"
            :prefix-icon="Search"
            @change="filterChange"
          />
          <el-select
            v-model="sortSelection"
            @change="filterChange"
            style="width: 150px"
            class="xl:mr-[20px] sm:!w-[110px] sm:mr-0"
            size="large"
          >
            <el-option
              v-for="item in sortOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <template v-if="repoType != 'code'">
            <div v-if="mobileScreen">
              <MobileTagSidebar
                :taskTags="taskTags"
                :frameworkTags="frameworkTags"
                :licenseTags="licenseTags"
                :industryTags="industryTags"
                @resetTags="resetTags"
                type="model"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="repoType === 'code'"
        class="grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-4 mb-4 mt-[16px] xl:pl-[20px] mlg:pl-0"
      >
        <repo-item
          v-for="repo in reposData"
          :repo="repo"
          :repo-type="repoType"
        />
      </div>
      <div
        v-else
        class="flex w-full flex-wrap justify-between md:gap-[12px] gap-[16px] px-[16px] py-[12px] md:p-0"
      >
        <repo-item
          v-for="repo in reposData"
          :repo="repo"
          :repo-type="repoType"
        />
      </div>
      <CsgPagination
        :perPage="perPage"
        :currentPage="currentPage"
        @currentChange="reloadRepos"
        :total="totalRepos"
      />
    </div>
  </div>
</template>
<script setup>
  import { onMounted, ref, computed, inject } from 'vue'
  import { Search } from '@element-plus/icons-vue'
  import { ElInput, ElMessage } from 'element-plus'
  import RepoItem from '../shared/RepoItem.vue'
  import ApplicationSpaceItem from '../application_spaces/ApplicationSpaceItem.vue'
  import TagSidebar from '../tags/TagSidebar.vue'
  import CsgPagination from './CsgPagination.vue'
  import { useI18n } from 'vue-i18n'
  import MobileTagSidebar from '../tags/MobileTagSidebar.vue';
  // import trackPageEvent from '../../../../packs/trackPageEvent'
  import useFetchApi from '../../../../packs/useFetchApi'

  const props = defineProps({
    selectedTag: String,
    selectedTagType: String,
    repoType: String
  })

  const tagFields = {
    model: [
      'computer_vision',
      'natural_language_processing',
      'audio_processing',
      'multimodal',
      'other'
    ],
    dataset: [
      'text_processing',
      'graphics',
      'audio',
      'video',
      'multimodal',
      'other'
    ],
    code: []
  }

  const onPremise = inject('onPremise', 'true')
  const { t } = useI18n()
  const taskTags = ref({})
  const frameworkTags = ref([])
  const languageTags = ref([])
  const licenseTags = ref([])
  const industryTags = ref([])
  const nameFilterInput = ref('')
  const sortSelection = ref('trending')
  const sourceSelection = ref('all')
  const currentPage = ref(1)
  const totalRepos = ref(0)
  const taskTag = ref('')
  const frameworkTag = ref('')
  const languageTag = ref('')
  const licenseTag = ref('')
  const industryTag = ref('')
  const reposData = ref(Array)
  const sortOptions = [
    {
      value: 'trending',
      label: t('all.trending')
    },
    {
      value: 'recently_update',
      label: t('all.recentlyUpdate')
    },
    {
      value: 'most_download',
      label: t('all.mostDownload')
    },
    // {
    //   value: 'most_favorite',
    //   label: t('all.mostFavorite')
    // }
  ]

  const sourceOptions = [
    {
      value: 'all',
      label: t('repo.source.all')
    },
    {
      value: 'caict',
      label: t('repo.source.caict')
    },
    {
      value: 'local',
      label: t('repo.source.local')
    }
  ]

  const perPage = computed(() => {
    if (props.repoType === 'space') {
      return 9
    } else if (props.repoType === 'code') {
      return 18
    } else {
      return 16
    }
  })
  const mobileScreen = ref(
    window.innerWidth < 768
  );

  const adapterScreenWidth = () => {
    mobileScreen.value = window.innerWidth < 768;
  };

  const onWindowResize = onMounted(() => {
    window.addEventListener("resize", adapterScreenWidth);
  });

  const resetTags = (task, framework, language, license, industry) => {
    taskTag.value = task
    frameworkTag.value = framework
    languageTag.value = language
    licenseTag.value = license
    industryTag.value = industry
    reloadRepos(1)
  }

  const filterChange = () => {
    reloadRepos(1)
  }

  const reloadRepos = (childCurrent) => {
    if(childCurrent){
      currentPage.value = childCurrent
    }
    let url = `/${props.repoType}s`
    url = url + `?page=${childCurrent ? childCurrent : currentPage.value}`
    url = url + `&per=${perPage.value}`
    url = url + `&search=${nameFilterInput.value}`
    url = url + `&sort=${sortSelection.value}`
    url = url + `&task_tag=${taskTag.value}`
    url = url + `&framework_tag=${frameworkTag.value}`
    url = url + `&language_tag=${languageTag.value}`
    url = url + `&license_tag=${licenseTag.value}`
    url = url + `&industry_tag=${industryTag.value}`
    url = url + `&source=${sourceSelection.value === 'all' ? '' : sourceSelection.value}`
    loadRepos(url)
    if (childCurrent) {
      addEvent(`page_${props.repoType}`, 'PageClick')
    } else if (
      nameFilterInput.value ||
      taskTag.value ||
      frameworkTag.value ||
      languageTag.value ||
      licenseTag.value ||
      industryTag.value
    ) {
      addEvent(`filter_${props.repoType}`, 'Filter')
    }
  }

  function addEvent(id, m) {
    // if (['model', 'dataset', 'code', 'space'].includes(props.repoType)) {
    //   trackPageEvent({ id: id, m: m })
    // }
  }

  async function loadRepos(url) {
    const { error, data } = await useFetchApi(url).json()
    if (!data.value) {
      ElMessage({
        message: error.value.msg || t('all.fetchError'),
        type: 'warning'
      })
    } else {
      reposData.value = data.value.data
      totalRepos.value = data.value.total
    }
  }

  async function fetchTags() {
    const { error, data } = await useFetchApi(`/tags`).json()
    if (!data.value) {
      ElMessage({
        message: error.value.msg || t('all.fetchError'),
        type: 'warning'
      })
    } else {
      let tempTaskTags = {}
      const allTaskTags = data.value.data.filter(tag => tag.category === 'task' && tag.scope === props.repoType && tag.built_in === true)
      tagFields[props.repoType].forEach((field) => {
        const fieldTags = allTaskTags.filter(tag => tag.group === field)
        tempTaskTags[field] = fieldTags
      })

      taskTags.value = tempTaskTags
      industryTags.value = data.value.data.filter(tag => tag.category === 'industry' && tag.scope === props.repoType && tag.built_in === true)
      // console.log(industryTags.value)
      frameworkTags.value = data.value.data.filter(tag => tag.category === 'framework' && tag.scope === props.repoType && tag.built_in === true)
      languageTags.value = data.value.data.filter(tag => tag.category === 'language' && tag.scope === props.repoType && tag.built_in === true)
      licenseTags.value = data.value.data.filter(tag => tag.category === 'license' && tag.scope === props.repoType && tag.built_in === true)
      // console.log(licenseTags.value)
    }
  }

  onMounted(() => {
    console.log("onMounted")
    if (props.repoType === 'code') {
      reloadRepos()
    }
    fetchTags()
  })
</script>
