import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg border border-[#EAECF0] hover:border-[#D0D5DD] transition-all duration-200 p-4" }
const _hoisted_2 = { class: "flex items-start justify-between mb-3" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "text-[16px] font-medium text-[#101828] group-hover:text-blue-600 transition-colors duration-200 line-clamp-1" }
const _hoisted_5 = { class: "space-y-2.5" }
const _hoisted_6 = { class: "flex items-center text-[#475467] text-[14px]" }
const _hoisted_7 = { class: "truncate" }
const _hoisted_8 = { class: "flex items-center text-[#475467] text-[14px]" }
const _hoisted_9 = { class: "truncate" }
const _hoisted_10 = { class: "flex items-center text-[#475467] text-[14px]" }
const _hoisted_11 = { class: "truncate" }
const _hoisted_12 = { class: "mt-4 pt-3 border-t border-[#EAECF0] flex justify-between items-center" }
const _hoisted_13 = ["href"]
const _hoisted_14 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_tag = _resolveComponent("el-tag")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 标题和状态区域 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: '/events/' + $props.event.id,
        class: "group flex-1 flex items-center gap-1"
      }, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString($props.event.title), 1 /* TEXT */),
        ($props.event.admin_message)
          ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              class: "text-orange-500 text-[14px] flex-shrink-0"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Warning"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ], 8 /* PROPS */, _hoisted_3),
      _createVNode(_component_el_tag, {
        type: $setup.getStatusType($props.event.status),
        size: "small",
        class: "ml-2 flex-shrink-0"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.getStatusText($props.event.status)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])
    ]),
    _createCommentVNode(" 信息列表 "),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_icon, { class: "mr-2 text-[16px]" }, {
          default: _withCtx(() => [
            _createVNode($setup["Calendar"])
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("span", _hoisted_7, _toDisplayString($setup.formatDate($props.event.event_date)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_el_icon, { class: "mr-2 text-[16px]" }, {
          default: _withCtx(() => [
            _createVNode($setup["Location"])
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("span", _hoisted_9, _toDisplayString($props.event.event_location), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_el_icon, { class: "mr-2 text-[16px]" }, {
          default: _withCtx(() => [
            _createVNode($setup["OfficeBuilding"])
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("span", _hoisted_11, _toDisplayString($props.event.event_leader), 1 /* TEXT */)
      ])
    ]),
    _createCommentVNode(" 底部链接 "),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("a", {
        href: '/events/' + $props.event.id,
        class: "inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200"
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("span", null, "查看详情", -1 /* HOISTED */)),
        _createVNode(_component_el_icon, { class: "ml-1.5 text-[16px]" }, {
          default: _withCtx(() => [
            _createVNode($setup["ArrowRight"])
          ]),
          _: 1 /* STABLE */
        })
      ], 8 /* PROPS */, _hoisted_13),
      ($setup.canEdit)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: '/events/' + $props.event.id + '/edit',
            class: "inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200"
          }, [
            _createVNode(_component_el_icon, { class: "mr-1.5 text-[16px]" }, {
              default: _withCtx(() => [
                _createVNode($setup["Edit"])
              ]),
              _: 1 /* STABLE */
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "编辑", -1 /* HOISTED */))
          ], 8 /* PROPS */, _hoisted_14))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}