export const colorMap = (type) => {
  const map = {
    'task': '#FFA801',
    'framework': '#2ECC71',
    'language': '#3498DB',
    'license': '#F56C6C',
    'industry': '#3498DB'
  }
  return map[type] || '#FF3F34'
}

export const stringMod = (string, divisor) => {
  let total = 0;
  for (let i = 0; i < string.length; i++) {
      total += string.charCodeAt(i);
  }
  return total % divisor;
}

export const getCommonThemeClass = (str) => {
  const themes = {
    0: "text-[#FF3F34] bg-[#ff3f341a]",
    1: "text-[#FFA801] bg-[#ffa8011a]",
    2: "text-[#67C23A] bg-[#F0F9EB]",
    3: "text-[#6927DA] bg-[#ECE9FE]",
    4: "text-[var(--Gray-700)] bg-[#F2F4F7]",
    5: "text-[#F97066] bg-[#fffbfa1a]",
  }
  const mod = stringMod(str, 6)
  return themes[mod]
}


export const industryList = [
  "汽车", "制造业", "能源", "通信与电子信息", "交通运输", "装备制造", "矿产与冶金", "建筑与房地产", "金融服务", "农业", "文化与出版", "生物医药", "医疗健康", "政务", "旅游", "教育", "基础平台", "基础软硬件", "原材料", "安防", "办公", "传媒", "法律", "科研智能", "软件工程", "石化化工", "食品", "水利", "体育", "营销", "其他"
]