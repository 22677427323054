<template>
  <div class="flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0">
    <div class="pt-[32px] w-full xl:pr-[20px] mlg:px-[20px]">
      <div v-if="isEventHolder" class="mb-8">
        <div class="flex xl:flex-col justify-between px-[16px] mb-4">
          <h3 class="text-lg font-normal text-[#101828] flex items-center gap-2">
            <span>我的活动总结</span>
          </h3>
          <div class="flex items-center">
            <a href="/event_conclusions/new" class="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <el-icon class="mr-1"><Plus /></el-icon>
              新建总结
            </a>
          </div>
        </div>
        <div class="grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-4 mb-4 mt-[16px] xl:pl-[20px] mlg:pl-0">
          <div v-for="conclusion in eventConclusions" :key="conclusion.id">
            <event-conclusion-card :conclusion="conclusion" />
          </div>
        </div>
      </div>

      <div v-if="isAdmin">
        <div class="flex xl:flex-col justify-between px-[16px] mb-4">
          <h3 class="text-lg font-normal text-[#101828] flex items-center gap-2">
            <span>活动总结报告查看</span>
          </h3>
          <div class="flex items-center gap-4">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              @change="handleDateRangeChange"
              class="!w-[360px]"
            />
            <el-button 
              v-if="hasDateFilter"
              size="default"
              class="!h-[32px]"
              @click="resetDateRange"
            >
              清除筛选
            </el-button>
            <el-button 
              type="primary"
              size="default"
              class="!h-[32px]"
              @click="handleExport"
            >
              导出表格
            </el-button>
          </div>
        </div>
        <div class="px-[16px] mb-2">
          <div class="bg-white rounded-lg border border-[#EAECF0] overflow-hidden">
            <div class="grid grid-cols-[3fr,2fr,180px,180px,180px,100px] gap-4 px-6 py-3 bg-gray-50 border-b border-[#EAECF0]">
              <div class="text-sm font-medium text-gray-500">标题</div>
              <div class="text-sm font-medium text-gray-500">主办单位</div>
              <div class="text-sm font-medium text-gray-500">活动时间</div>
              <div class="text-sm font-medium text-gray-500">活动地点</div>
              <div class="text-sm font-medium text-gray-500">更新时间</div>
              <div class="text-sm font-medium text-gray-500 text-right">详情</div>
            </div>
            <div v-for="conclusion in adminEventConclusions" :key="conclusion.id" class="grid grid-cols-[3fr,2fr,180px,180px,180px,100px] gap-4 px-6 py-4 border-b border-[#EAECF0] hover:bg-gray-50">
              <div class="text-sm text-gray-900 truncate">
                <a :href="'/event_conclusions/' + conclusion.id" class="hover:text-blue-600">{{ conclusion.title }}</a>
              </div>
              <div class="text-sm text-gray-500 truncate">{{ conclusion.event_leader }}</div>
              <div class="text-sm text-gray-500 whitespace-nowrap">{{ formatDate(conclusion.event_date) }}</div>
              <div class="text-sm text-gray-500 truncate">{{ conclusion.event_location }}</div>
              <div class="text-sm text-gray-500 whitespace-nowrap">{{ formatDateTime(conclusion.updated_at) }}</div>
              <div class="text-right">
                <a :href="'/event_conclusions/' + conclusion.id" class="text-sm text-gray-500 hover:text-blue-600">查看详情</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import EventConclusionCard from './EventConclusionCard.vue'
import { Plus } from '@element-plus/icons-vue'
import { ref, computed } from 'vue'
import { exportEventConclusionCSV } from '@api/csrf/event_conclusion'
import { ElMessage } from 'element-plus'

const dateRange = ref([])

// 检查URL中是否有日期筛选参数
const hasDateFilter = computed(() => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.has('start_date') && searchParams.has('end_date')
})

// 初始化日期范围
if (hasDateFilter.value) {
  const searchParams = new URLSearchParams(window.location.search)
  dateRange.value = [
    searchParams.get('start_date'),
    searchParams.get('end_date')
  ]
}

const props = defineProps({
  eventConclusions: {
    type: Array,
    default: () => []
  },
  adminEventConclusions: {
    type: Array,
    default: () => []
  },
  isEventHolder: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['dateRangeChange'])

const handleDateRangeChange = (val) => {
  if (val) {
    window.location.href = `/event_conclusions?start_date=${val[0]}&end_date=${val[1]}`
  } else {
    window.location.href = '/event_conclusions'
  }
}

const formatDate = (date) => {
  if (!date) return ''
  const d = new Date(date)
  return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
}

const formatDateTime = (datetime) => {
  if (!datetime) return ''
  const d = new Date(datetime)
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')} ${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`
}

const resetDateRange = () => {
  dateRange.value = null
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete('start_date')
  searchParams.delete('end_date')
  const newSearch = searchParams.toString()
  window.location.href = `/event_conclusions${newSearch ? '?' + newSearch : ''}`
}

const handleExport = async () => {
  try {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {
      start_date: searchParams.get('start_date') || '',
      end_date: searchParams.get('end_date') || ''
    }
    
    const response = await exportEventConclusionCSV(params)
    
    // 创建Blob对象
    const blob = new Blob([response], { type: 'text/csv;charset=UTF-8' })
    
    // 创建下载链接
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = params.start_date && params.end_date
      ? `活动总结报告(${params.start_date}至${params.end_date}).csv`
      : '活动总结报告_全部.csv'
    
    // 触发下载
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href)
  } catch (error) {
    console.error('导出失败:', error)
    ElMessage.error('导出失败，请重试')
  }
}
</script> 