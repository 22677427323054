import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-container pt-4" }
const _hoisted_2 = { class: "search-and-button-container mb-4 mt-6" }
const _hoisted_3 = { class: "inner-container" }
const _hoisted_4 = { class: "search-container relative" }
const _hoisted_5 = { class: "flex items-center justify-between px-4 mb-2" }
const _hoisted_6 = {
  class: "main-content",
  id: "pills-tabContent"
}
const _hoisted_7 = { class: "text-xl font-bold mb-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.searchKeyword) = $event)),
              type: "text",
              placeholder: "搜索AI工具...",
              class: "w-full pl-10 pr-4 py-2 rounded-full border focus:outline-none focus:ring-2 focus:ring-[#418fff] focus:bg-gray-100 hover:bg-gray-100 transition-all duration-300 ease-in-out shadow-lg",
              onInput: $setup.handleSearch
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, $setup.searchKeyword]
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" }, [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                class: "w-5 h-5"
              }, [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  d: "m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                })
              ])
            ], -1 /* HOISTED */))
          ])
        ])
      ]),
      _createVNode($setup["NavigationTabs"], {
        "grouped-items": $setup.groupedItems,
        "current-section": $setup.currentSection,
        "is-searching": $setup.isSearching,
        onTabClick: $setup.handleTabClick
      }, null, 8 /* PROPS */, ["grouped-items", "current-section", "is-searching"]),
      _createElementVNode("div", _hoisted_5, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-1" }, null, -1 /* HOISTED */)),
        _createVNode($setup["SortButton"], {
          modelValue: $setup.sortOrder,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.sortOrder) = $event)),
          onToggleSort: $setup.toggleSortOrder
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("div", {
              key: $setup.isSearching ? 'search' : $setup.currentSection,
              class: "tab-pane",
              role: "tabpanel",
              tabindex: "0"
            }, [
              ($setup.isSearching)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.filteredGroups, (group) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: group.appclass
                    }, [
                      _createElementVNode("h3", _hoisted_7, _toDisplayString(group.appclass), 1 /* TEXT */),
                      _createVNode($setup["WebItemGrid"], {
                        items: group.items
                      }, null, 8 /* PROPS */, ["items"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                : ($setup.currentGroup)
                  ? (_openBlock(), _createBlock($setup["WebItemGrid"], {
                      key: 1,
                      items: $setup.currentGroup
                    }, null, 8 /* PROPS */, ["items"]))
                  : _createCommentVNode("v-if", true)
            ]))
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _createVNode($setup["AddBubble"])
  ], 64 /* STABLE_FRAGMENT */))
}