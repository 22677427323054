<template>
  <div>
    <el-card>
      <template #header>
        <div class="card-header">
          <span>User Detail</span>
        </div>
      </template>
      <ul class="max-w-[480px]">
        <li class="flex justify-between mb-4">
          <label>Uuid</label>
          <p>{{ user.uuid }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Name</label>
          <p>{{ user.username }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Nickname</label>
          <p>{{ user.nickname }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Avatar</label>
          <p>{{ user.avatar }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Email</label>
          <p>{{ user.email }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Phone</label>
          <p>{{ user.phone }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Roles</label>
          <p class="flex gap-2">
            <el-tag
              v-for="role in user.roles"
              :key="role"
              size="small"
            >
              {{ role }}
            </el-tag>
          </p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Created At</label>
          <p>{{ dayjs(user.created_at).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </li>
        <!-- <li class="flex justify-between mb-4">
          <label>Credit</label>
          <div class="flex items-center">
            <p class="mr-3">{{ credit }} 元</p>
            <div
              @click="refreshDialogVisible = true"
              class="border px-[16px] rounded-lg border-amber-50 bg-orange-500 cursor-pointer"
            >
              {{ $t('new_admin.user.recharge') }}
            </div>
          </div>
        </li> -->
      </ul>
      <template #footer>
        <el-button @click="dialogFormVisible = true">Edit</el-button>
      </template>
    </el-card>

    <!-- recharge dialog -->
    <el-dialog
      v-model="refreshDialogVisible"
      :title="$t('new_admin.user.recharge')"
      width="20%"
      class="dialogWidth"
      style="border-radius: 0.5rem;"
      left
    >
      <div class="mb-[16px]">
        <p class="text-[#303133] text-[14px] mb-[8px]">
          {{ $t('new_admin.user.inputTitle') }}
          <span class="text-red-400">*</span>
        </p>
        <el-input
          v-model="rechargeAmount"
          type="number"
          :placeholder="$t('new_admin.user.inputPlaceholder')"
          maxlength="20"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clickCancel">{{ $t('new_admin.user.cancel') }}</el-button>
          <el-button
            type="primary"
            @click="fetchRecharge"
          >
            {{ $t('new_admin.user.confirm') }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- edit user infos dialog -->
    <el-dialog
      v-model="dialogFormVisible"
      :title="`${user.username}`"
      width="500"
    >
      <el-form :model="form">
        <el-form-item label="Roles" prop="roles">
          <el-select
            v-model="form.roles"
            multiple
            placeholder="Please select the roles"
            class="w-full"
            clearable
            collapse-tags
            collapse-tags-tooltip
          >
            <el-option
              v-for="role in availableRoles"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="submitUserForm"
          >
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import useFetchApi from '../../../packs/useFetchApi'
  import dayjs from 'dayjs'
  import { ElMessage } from 'element-plus'

  const route = useRoute()

  const user = ref({})
  const credit = ref(0)
  const refreshDialogVisible = ref(false)
  const rechargeAmount = ref(0)

  const dialogFormVisible = ref(false)

  const form = ref({
    roles: []
  })

  const availableRoles = [
    { label: 'Super User', value: 'super_user' },
    { label: 'Admin', value: 'admin' },
    { label: 'Personal User', value: 'personal_user' },
    { label: 'Event Holder', value: 'event_holder' },
    { label: 'Event Admin', value: 'event_admin'}
  ]

  watch(user, () => {
    // fetchCredit()
  })

  const fetchUser = async () => {
    const { data } = await useFetchApi(`/user/${route.params.id}`).json()
    if (data.value) {
      const result = data.value
      user.value = result.data
      form.value.roles = Array.isArray(result.data.roles) ? result.data.roles : []
    } else {
      ElMessage.warning(result.msg)
    }
  }

  // const fetchCredit = async () => {
  //   const { data, error } = await useFetchApi(`/accounting/credit/${user.value.uuid}/balance`).json()

  //   if (data.value) {
  //     const result = data.value
  //     credit.value = (result.data.balance/100.0).toFixed(2)
  //   } else {
  //     ElMessage.warning(error.value.msg)
  //   }
  // }

  // const fetchRecharge = async () => {
  //   const params = {
  //     op_uid: 1,
  //     value: rechargeAmount.value * 100
  //   }
  //   const options = {
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(params)
  //   }
  //   const { data, error } = await useFetchApi(`/accounting/credit/${user.value.uuid}/recharge`, options).put().json()

  //   if (data.value) {
  //     const result = data.value
  //     credit.value = (result.data.balance/100.0).toFixed(2)
  //     refreshDialogVisible.value = false
  //     rechargeAmount.value = 0
  //     ElMessage.success('Be recharged successfully')
  //   } else {
  //     ElMessage.warning(error.value.msg)
  //   }
  // }

  const clickCancel = () => {
    refreshDialogVisible.value = false
    rechargeAmount.value = 0
  }

  const submitUserForm = async () => {
    const params = {
      roles: form.value.roles.map(role => String(role))
    }
    
    const { data, error } = await useFetchApi(`/user/${user.value.username}`, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).put().json()
    
    if (data.value) {
      ElMessage.success('User updated successfully')
      dialogFormVisible.value = false
      fetchUser()
    } else {
      ElMessage.warning(error.value?.msg || 'Failed to update user')
    }
  }

  onMounted(() => {
    fetchUser()
  })
</script>
