<template>
  <div class="external-sources-select w-full">
    <div class="mb-2">
      <span class="text-[#344054] text-[14px]">外部来源</span>
    </div>
    
    <!-- 外部源列表 -->
    <div class="external-sources-list">
      <div v-for="(source, index) in sources" :key="index" class="mb-3 flex items-center gap-3">
        <!-- 来源选择 -->
        <el-select 
          v-model="source.name"
          class="!w-[180px] !h-[40px] flex-shrink-0"
          placeholder="选择来源"
        >
          <el-option
            v-for="option in sourceOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          >
            <div class="flex items-center gap-2">
              <img 
                :src="`/images/xty_images/external_sources/${option.value}.png`"
                class="w-4 h-4"
                alt=""
              />
              <span>{{ option.label }}</span>
            </div>
          </el-option>
        </el-select>

        <!-- URL输入 -->
        <div class="flex-1 flex items-center gap-1 bg-[#F5F7FA] rounded-[4px] px-3">
          <span class="text-[#909399] text-[14px] whitespace-nowrap">{{ getSourceConfig(source.name).prefix }}</span>
          <el-input 
            v-model="source.path"
            class="!min-w-[200px] flex-1 !h-[40px]"
            :placeholder="getSourceConfig(source.name).placeholder"
          />
        </div>

        <!-- 删除按钮 -->
        <el-button 
          type="danger" 
          circle
          class="!h-[40px] !w-[40px] flex-shrink-0"
          @click="removeSource(index)"
          :icon="Delete"
        />
      </div>
    </div>

    <!-- 添加按钮 -->
    <el-button 
      type="primary" 
      plain
      class="mt-2"
      @click="addSource"
      :icon="Plus"
    >
      添加来源
    </el-button>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { Delete, Plus } from '@element-plus/icons-vue'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update:modelValue'])

// 外部源选项和对应的URL前缀
const sourceConfigs = {
  modelscope: {
    label: '魔搭社区',
    prefix: 'https://modelscope.cn/',
    placeholder: '请补全路径'
  },
  aistudio: {
    label: '飞桨社区',
    prefix: 'https://aistudio.baidu.com/',
    placeholder: '请补全路径'
  },
  modelers: {
    label: '魔乐社区',
    prefix: 'https://www.modelers.cn/',
    placeholder: '请补全路径'
  },
  wisemodel: {
    label: '始智社区',
    prefix: 'https://wisemodel.cn/',
    placeholder: '请补全路径'
  },
  opencsg: {
    label: '开放传神社区',
    prefix: 'https://opencsg.com/',
    placeholder: '请补全路径'
  },
  giteeai:{
    label: 'GiteeAI',
    prefix: 'https://ai.gitee.com/',
    placeholder: '请补全路径'
  }
}

// 外部源选项
const sourceOptions = Object.entries(sourceConfigs).map(([value, config]) => ({
  value,
  label: config.label
}))

// 当前选择的外部源列表
const sources = ref(props.modelValue.length > 0 
  ? props.modelValue.map(s => {
      const config = sourceConfigs[s.source_name]
      return {
        name: s.source_name,
        path: config ? s.source_url.replace(config.prefix, '') : s.source_url
      }
    })
  : [{ name: '', path: '' }]
)

// 获取当前选中源的配置
const getSourceConfig = (sourceName) => {
  return sourceConfigs[sourceName] || { 
    prefix: '', 
    placeholder: '请选择来源社区'
  }
}

// 添加新的外部源
const addSource = () => {
  sources.value.push({ name: '', path: '' })
}

// 删除外部源
const removeSource = (index) => {
  sources.value.splice(index, 1)
}

// 监听变化并更新父组件的值
watch(sources, (newVal) => {
  const validSources = newVal.filter(s => s.name && s.path)
  emit('update:modelValue', validSources.map(s => ({
    source_name: s.name,
    source_url: sourceConfigs[s.name].prefix + s.path
  })))
}, { deep: true })
</script>

<style scoped>
:deep(.el-input__wrapper) {
  height: 40px;
  line-height: 40px;
  background-color: transparent !important;
  box-shadow: none !important;
}

:deep(.el-select__wrapper) {
  height: 40px;
  line-height: 40px;
}

:deep(.el-select .el-input) {
  height: 40px;
}

:deep(.el-input) {
  height: 40px;
}

/* 确保输入框不会被压缩 */
:deep(.el-input__wrapper) {
  min-width: 0;
  width: 100%;
}

:deep(.el-select) {
  width: 180px !important;
}
</style> 