import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex items-center mb-[5px] w-full gap-[8px]" }
const _hoisted_3 = {
  key: 0,
  class: "flex-shrink-0"
}
const _hoisted_4 = {
  key: 1,
  class: "px-[8px] px-[3px] border border-[#DCDFE6] rounded-[4px] text-[12px] text-[#303133] font-[400] leading-[18px]"
}
const _hoisted_5 = {
  key: 0,
  class: "w-full h-[35px] leading-[18px] mb-[5px] text-[#909399] text-xs overflow-hidden text-ellipsis line-clamp-2 text-left"
}
const _hoisted_6 = { class: "flex flex-nowrap overflow-hidden text-ellipsis items-center gap-[8px] text-xs text-[#909399]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: $setup.detailLink,
    class: "basis-1/2 md:basis-full max-w-[532px] md:max-w-full flex flex-col xty-model-card md:bg-white bg-unset"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([`${$props.repoType}-path`, "text-sm text-[#303133] font-medium text-ellipsis overflow-hidden whitespace-nowrap max-w-[266px]"])
      }, _toDisplayString($setup.getComputed.displayName), 3 /* TEXT, CLASS */),
      ($props.repo.tags && $props.repo.tags.length > 0 && !!$setup.displayTag($props.repo.tags))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass(`text-[12px] px-[8px] py-[3px] flex gap-[4px] font-[400] rounded-[4px] bg-[${$setup.colorMap($setup.displayTag($props.repo.tags).category)}30] text-[${$setup.colorMap($setup.displayTag($props.repo.tags).category)}]`)
            }, _toDisplayString($setup.displayTag($props.repo.tags).show_name || $setup.displayTag($props.repo.tags).name), 3 /* TEXT, CLASS */)
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode("      <div v-if=\"repo.external_sources\" class=\"px-[8px] py-[3px] flex items-center gap-[4px] bg-[#F0F7FF] text-[#0C266E] text-[12px] font-[400] leading-[18px] rounded-[4px]\">"),
      _createCommentVNode("        <span>外部源</span>"),
      _createCommentVNode("      </div>"),
      ($props.repo.private)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 私有 "))
        : _createCommentVNode("v-if", true)
    ]),
    ($setup.getComputed.showDescription)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.repo.description), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_6, [
      _createCommentVNode(" <span v-if=\"getComputed.taskTag\"\r\n            class=\"max-w-[80px] xl:max-w-full overflow-hidden text-ellipsis whitespace-nowrap\"\r\n      >\r\n            {{ getComputed.taskTag }}\r\n      </span>\r\n\r\n      <span v-if=\"getComputed.taskTag\">\r\n        <SvgIcon name=\"vertical_divider\" />\r\n      </span> "),
      _createVNode($setup["ShortInfo"], { repo: $props.repo }, null, 8 /* PROPS */, ["repo"]),
      _createCommentVNode(" <div class=\"overflow-hidden text-ellipsis whitespace-nowrap\">\r\n        {{$t('all.lastTime')}}：{{ repo.updated_at.substring(0, 10) }}\r\n      </div>\r\n\r\n      <div class=\"overflow-hidden text-ellipsis whitespace-nowrap\">\r\n        {{$t('all.lastTime')}}：{{ repo.updated_at.substring(0, 10) }}\r\n      </div>\r\n\r\n      <span> <SvgIcon name=\"vertical_divider\" /> </span>\r\n\r\n      <span class=\"whitespace-nowrap\">{{ getComputed.visibility }}</span>\r\n\r\n      <span> <SvgIcon name=\"vertical_divider\" /> </span>\r\n\r\n      <span class=\"whitespace-nowrap\">{{$t('all.downloadCount')}}：{{ repo.downloads }}</span> ")
    ])
  ], 8 /* PROPS */, _hoisted_1))
}