<template>
  <div class="bg-white rounded-lg border border-[#EAECF0] hover:border-[#D0D5DD] transition-all duration-200 p-4">
    <!-- 标题和状态区域 -->
    <div class="flex items-start justify-between mb-3">
      <a :href="'/events/' + event.id" class="group flex-1 flex items-center gap-1">
        <h2 class="text-[16px] font-medium text-[#101828] group-hover:text-blue-600 transition-colors duration-200 line-clamp-1">
          {{ event.title }}
        </h2>
        <el-icon v-if="event.admin_message" class="text-orange-500 text-[14px] flex-shrink-0"><Warning /></el-icon>
      </a>
      <el-tag :type="getStatusType(event.status)" size="small" class="ml-2 flex-shrink-0">
        {{ getStatusText(event.status) }}
      </el-tag>
    </div>

    <!-- 信息列表 -->
    <div class="space-y-2.5">
      <div class="flex items-center text-[#475467] text-[14px]">
        <el-icon class="mr-2 text-[16px]"><Calendar /></el-icon>
        <span class="truncate">{{ formatDate(event.event_date) }}</span>
      </div>
      <div class="flex items-center text-[#475467] text-[14px]">
        <el-icon class="mr-2 text-[16px]"><Location /></el-icon>
        <span class="truncate">{{ event.event_location }}</span>
      </div>
      <div class="flex items-center text-[#475467] text-[14px]">
        <el-icon class="mr-2 text-[16px]"><OfficeBuilding /></el-icon>
        <span class="truncate">{{ event.event_leader }}</span>
      </div>
    </div>

    <!-- 底部链接 -->
    <div class="mt-4 pt-3 border-t border-[#EAECF0] flex justify-between items-center">
      <a :href="'/events/' + event.id" 
         class="inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200">
        <span>查看详情</span>
        <el-icon class="ml-1.5 text-[16px]"><ArrowRight /></el-icon>
      </a>
      <a v-if="canEdit" 
         :href="'/events/' + event.id + '/edit'"
         class="inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200">
        <el-icon class="mr-1.5 text-[16px]"><Edit /></el-icon>
        <span>编辑</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { Calendar, Location, OfficeBuilding, ArrowRight, Edit, Warning, Document } from '@element-plus/icons-vue'
import { computed } from 'vue'

const props = defineProps({
  event: {
    type: Object,
    required: true
  }
})

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

const getStatusType = (status) => {
  const types = {
    draft: '',
    submitted: 'warning',
    approved: 'success',
    rejected: 'danger'
  }
  return types[status] || ''
}

const getStatusText = (status) => {
  const texts = {
    draft: '草稿',
    submitted: '审核中',
    approved: '已通过',
    rejected: '已拒绝'
  }
  return texts[status] || status
}

// 计算属性：是否可以编辑
const canEdit = computed(() => {
  return props.event.status === 'draft' || props.event.status === 'rejected'
})
</script>

<style scoped>
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style> 