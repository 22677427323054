<template>
  <div>
    <div class="fixed bottom-24 right-6 z-50">
      <a
        href="https://u0ddt94fk3b.feishu.cn/share/base/form/shrcnLB9wIuL47emhQNRO87Hn1c"
        target="_blank"
        rel="noopener noreferrer"
        class="flex items-center gap-2 px-4 py-2 bg-[#FF5005] text-white rounded-full shadow-lg hover:bg-[#e64600] transition-all duration-300 hover:scale-105 group"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
        申请添加AI工具
        <span class="absolute -top-10 right-0 bg-gray-800 text-white text-sm px-3 py-1 rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          提交您想要添加的AI工具
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddBubble'
}
</script>

<style>
</style>
