import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "external-sources-select w-full" }
const _hoisted_2 = { class: "external-sources-list" }
const _hoisted_3 = { class: "flex items-center gap-2" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex-1 flex items-center gap-1 bg-[#F5F7FA] rounded-[4px] px-3" }
const _hoisted_6 = { class: "text-[#909399] text-[14px] whitespace-nowrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-2" }, [
      _createElementVNode("span", { class: "text-[#344054] text-[14px]" }, "外部来源")
    ], -1 /* HOISTED */)),
    _createCommentVNode(" 外部源列表 "),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sources, (source, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mb-3 flex items-center gap-3"
        }, [
          _createCommentVNode(" 来源选择 "),
          _createVNode(_component_el_select, {
            modelValue: source.name,
            "onUpdate:modelValue": $event => ((source.name) = $event),
            class: "!w-[180px] !h-[40px] flex-shrink-0",
            placeholder: "选择来源"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sourceOptions, (option) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: option.value,
                  label: option.label,
                  value: option.value
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("img", {
                        src: `/images/xty_images/external_sources/${option.value}.png`,
                        class: "w-4 h-4",
                        alt: ""
                      }, null, 8 /* PROPS */, _hoisted_4),
                      _createElementVNode("span", null, _toDisplayString(option.label), 1 /* TEXT */)
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]),
          _createCommentVNode(" URL输入 "),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString($setup.getSourceConfig(source.name).prefix), 1 /* TEXT */),
            _createVNode(_component_el_input, {
              modelValue: source.path,
              "onUpdate:modelValue": $event => ((source.path) = $event),
              class: "!min-w-[200px] flex-1 !h-[40px]",
              placeholder: $setup.getSourceConfig(source.name).placeholder
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder"])
          ]),
          _createCommentVNode(" 删除按钮 "),
          _createVNode(_component_el_button, {
            type: "danger",
            circle: "",
            class: "!h-[40px] !w-[40px] flex-shrink-0",
            onClick: $event => ($setup.removeSource(index)),
            icon: $setup.Delete
          }, null, 8 /* PROPS */, ["onClick", "icon"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" 添加按钮 "),
    _createVNode(_component_el_button, {
      type: "primary",
      plain: "",
      class: "mt-2",
      onClick: $setup.addSource,
      icon: $setup.Plus
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" 添加来源 ")
      ])),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["icon"])
  ]))
}