import { rule } from './en_js/rule';
import { models } from './en_js/models';
import { comment } from './en_js/comment';
import { datasets } from './en_js/datasets';
import { organization } from './en_js/organization';
import { navbar } from './en_js/navbar';
import { all } from './en_js/all';
import { community } from './en_js/community';
import { profile } from './en_js/profile';
import { gitToken } from './en_js/gitToken';
import { sshKey } from './en_js/sshKey';
import { shared } from './en_js/shared';
import { repo } from './en_js/repo';
import { application_spaces } from './en_js/application_spaces';
import { codes } from './en_js/codes';
import { widgets } from './en_js/widgets';
import { spaces } from './en_js/spaces';
import { user_sessions } from './en_js/user_sessions';
import { components } from './en_js/components';
import { space } from './en_js/space';
import { campaign } from './en_js/campaign';
import { computing } from './en_js/computing';
import { product } from './en_js/product';
import { solution } from './en_js/solution';
import { dailyPaper } from './en_js/dailyPaper';
import { form } from './en_js/form';
import { landingPage } from './en_js/landing_page';
import { footer } from './en_js/footer';
import { ekb } from './en_js/ekb';
import { partner } from './en_js/partner';
import { accessToken } from './en_js/accessToken';
import { tags } from './en_js/tags';
import { new_admin } from './en_js/new_admin';
import { admin } from './en_js/admin';

export default {
  // jingzhi
  all,
  rule,
  user_sessions,
  spaces,
  application_spaces,
  widgets,
  models,
  comment,
  datasets,
  codes,
  organization,
  navbar,
  community,
  profile,
  gitToken,
  sshKey,
  shared,
  repo,
  components,

  // portal
  space,
  campaign,
  computing,
  product,
  solution,
  dailyPaper,
  form,
  landingPage,
  footer,
  ekb,
  partner,
  accessToken,
  tags,
  new_admin,
  admin
};
