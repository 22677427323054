import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full md:border-0 community-border-b" }
const _hoisted_2 = { class: "max-w-[1440px] md:max-w-full m-auto flex md:justify-between justify-start items-center md:h-[44px] h-[56px] md:px-[16px] px-[32px] md:gap-[0px] gap-[16px] relative w-full" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { href: "/" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = {
  key: 0,
  class: "el-dropdown-link relative"
}
const _hoisted_8 = {
  key: 1,
  class: "el-dropdown-link relative"
}
const _hoisted_9 = {
  key: 2,
  class: "el-dropdown-link"
}
const _hoisted_10 = { href: "/settings/profile" }
const _hoisted_11 = { href: "/models/new" }
const _hoisted_12 = { href: "/datasets/new" }
const _hoisted_13 = { href: "/codes/new" }
const _hoisted_14 = { href: "/organizations/new" }
const _hoisted_15 = {
  key: 0,
  href: "/daily_papers/new"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("a", _hoisted_4, [
            _createElementVNode("img", {
              src: $props.logo.header_logo,
              alt: "Community Logo",
              class: "h-[32px]"
            }, null, 8 /* PROPS */, _hoisted_5)
          ])
        ])
      ]),
      _createVNode($setup["menusV2"], {
        menu: $props.menu,
        userRoles: $setup.userRoles
      }, null, 8 /* PROPS */, ["menu", "userRoles"]),
      _createElementVNode("div", _hoisted_6, [
        ($setup.isLoggedInBoolean)
          ? (_openBlock(), _createBlock(_component_el_dropdown, {
              key: 0,
              class: "pl-1"
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createElementVNode("a", { href: $setup.userProfile }, [
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" 个人信息 ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _createElementVNode("a", _hoisted_10, [
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" 账号设置 ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _createCommentVNode(" <el-dropdown-item @click=\"showDialog\" > 联系我们 </el-dropdown-item> "),
                    _createElementVNode("a", _hoisted_11, [
                      _createVNode(_component_el_dropdown_item, { divided: "" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" + 新建模型 ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _createElementVNode("a", _hoisted_12, [
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" + 新建数据集 ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _createElementVNode("a", _hoisted_13, [
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" + 新建工具集 ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _createElementVNode("a", _hoisted_14, [
                      _createVNode(_component_el_dropdown_item, { divided: "" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" 新建组织 ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    ($props.canCreateDailyPaper)
                      ? (_openBlock(), _createElementBlock("a", _hoisted_15, [
                          _createVNode(_component_el_dropdown_item, null, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" 论文推荐 ")
                            ])),
                            _: 1 /* STABLE */
                          })
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("a", { onClick: $setup.clearCookies }, [
                      _createVNode(_component_el_dropdown_item, { divided: "" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" 退出登录 ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              default: _withCtx(() => [
                (JSON.parse($props.companyVerified.toLowerCase()))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _createVNode(_component_el_avatar, {
                        size: 26,
                        src: $props.avatar
                      }, null, 8 /* PROPS */, ["src"]),
                      _cache[0] || (_cache[0] = _createElementVNode("svg", {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "15",
                        height: "15",
                        viewBox: "0 0 15 15",
                        fill: "none",
                        class: "absolute bottom-0 right-0"
                      }, [
                        _createElementVNode("rect", {
                          x: "0.5",
                          y: "0.5",
                          width: "14",
                          height: "14",
                          rx: "7",
                          fill: "white"
                        }),
                        _createElementVNode("path", {
                          d: "M7.5 1C3.88208 1 1 3.88208 1 7.5C1 11.1179 3.88208 14 7.5 14C11.1179 14 14 11.1179 14 7.5C14 3.88208 11.1179 1 7.5 1ZM11.3019 11.3632H3.57547V10.5047H4.92453V7.00943H5.78302V10.5047H7.31604V5.66038H8.17453V7.62264H10.6274V8.48113H8.17453V10.5047H11.3019V11.3632ZM11.3632 7.00943C9.89151 6.21226 8.60377 5.23113 7.5 3.9434C6.51887 5.10849 5.23113 6.15094 3.63679 7.00943L3.14623 6.15094C4.74057 5.35377 6.08962 4.37264 7.1934 3.14623H7.86792C9.03302 4.43396 10.3821 5.41509 11.8538 6.15094L11.3632 7.00943Z",
                          fill: "#67C23A"
                        }),
                        _createElementVNode("rect", {
                          x: "0.5",
                          y: "0.5",
                          width: "14",
                          height: "14",
                          rx: "7",
                          stroke: "white"
                        })
                      ], -1 /* HOISTED */))
                    ]))
                  : (JSON.parse($props.isCompanyUser.toLowerCase()))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                        _createVNode(_component_el_avatar, {
                          size: 26,
                          src: $setup.userAvatar
                        }, null, 8 /* PROPS */, ["src"]),
                        _cache[1] || (_cache[1] = _createElementVNode("svg", {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "15",
                          height: "15",
                          viewBox: "0 0 15 15",
                          fill: "none",
                          class: "absolute bottom-0 right-0"
                        }, [
                          _createElementVNode("rect", {
                            x: "0.5",
                            y: "0.5",
                            width: "14",
                            height: "14",
                            rx: "7",
                            fill: "black",
                            "fill-opacity": "0.8"
                          }),
                          _createElementVNode("path", {
                            d: "M7.5 1C3.88208 1 1 3.88208 1 7.5C1 11.1179 3.88208 14 7.5 14C11.1179 14 14 11.1179 14 7.5C14 3.88208 11.1179 1 7.5 1ZM11.3019 11.3632H3.57547V10.5047H4.92453V7.00943H5.78302V10.5047H7.31604V5.66038H8.17453V7.62264H10.6274V8.48113H8.17453V10.5047H11.3019V11.3632ZM11.3632 7.00943C9.89151 6.21226 8.60377 5.23113 7.5 3.9434C6.51887 5.10849 5.23113 6.15094 3.63679 7.00943L3.14623 6.15094C4.74057 5.35377 6.08962 4.37264 7.1934 3.14623H7.86792C9.03302 4.43396 10.3821 5.41509 11.8538 6.15094L11.3632 7.00943Z",
                            fill: "white"
                          }),
                          _createElementVNode("rect", {
                            x: "0.5",
                            y: "0.5",
                            width: "14",
                            height: "14",
                            rx: "7",
                            stroke: "#DCDFE6"
                          })
                        ], -1 /* HOISTED */))
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                        _createVNode($setup["UserAvatar"], {
                          typo: "sm",
                          avatar: $setup.userAvatar,
                          flag: $props.userInfo.flag
                        }, null, 8 /* PROPS */, ["avatar", "flag"])
                      ]))
              ]),
              _: 1 /* STABLE */
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[10] || (_cache[10] = _createElementVNode("a", {
                class: "sm:hidden min-w-[72px] sm:min-w-[46px] btn-orange rounded-[8px] px-[12px] py-[5px] flex items-center justify-center text-[12px] font-500 leading-[20px]",
                href: "/login"
              }, "登录", -1 /* HOISTED */)),
              _cache[11] || (_cache[11] = _createElementVNode("a", {
                class: "hidden sm:block min-w-[72px] sm:min-w-[46px] btn-orange rounded-[8px] px-[12px] py-[5px] flex items-center justify-center text-[12px] font-500 leading-[20px]",
                href: "/login"
              }, " 登录 ", -1 /* HOISTED */))
            ], 64 /* STABLE_FRAGMENT */))
      ])
    ])
  ]))
}