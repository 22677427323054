<template>
  <div>
    <div class="grid grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 px-4 py-3">
      <div
          v-for="(item, idx) in displayedItems"
          :key="idx"
          class="bg-white border border-[#E6E6E6] rounded-[8px] shadow-[0_0_0_0_rgba(0,0,0,0.05)] hover:border-[#0C266E] overflow-hidden cursor-pointer transition-all duration-300 relative group"
          @click="openweb(item.url)"
      >
        <div class="p-3 h-full flex flex-col">
          <div class="flex items-center space-x-2 mb-2">
            <img
                :src="item.logo"
                :alt="item.title"
                class="w-8 h-8 rounded-[4px] object-cover flex-shrink-0"
            />
            <h4 class="text-[rgba(0,0,0,0.88)] text-[14px] font-[400] leading-[20px] tracking-[0.014px] truncate flex-grow">{{ item.title }}</h4>
          </div>
          <p class="text-[#475467] text-[12px] leading-[18px] line-clamp-2 flex-grow">{{ item.desc }}</p>
        </div>
        <div v-if="isNewItem(item)" class="absolute top-0 right-0 bg-[#FF5005] text-white px-2 py-1 text-xs font-semibold rounded-bl-lg">
          新
        </div>
        <div class="absolute inset-0 bg-[#101828] bg-opacity-90 text-white p-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 overflow-y-auto">
          <p class="text-[13px] leading-[20px] tracking-[0.014px]">{{ item.desc }}</p>
        </div>
      </div>
    </div>

    <!-- 分页控件 -->
    <div v-if="totalPages > 1" class="flex justify-center items-center space-x-2 mt-6 mb-8">
      <button
          @click="changePage(currentPage - 1)"
          :disabled="currentPage === 1"
          class="px-4 py-2 rounded-[8px] text-[14px] leading-[20px] transition-colors duration-300 border"
          :class="currentPage === 1 ? 'text-[#98A2B3] bg-[#F9FAFB] border-[#EAECF0]' : 'text-[#344054] hover:bg-[#F9FAFB] border-[#D0D5DD]'"
      >
        上一页
      </button>
      <div class="flex items-center space-x-1">
        <button
            v-for="page in displayedPages"
            :key="page"
            @click="changePage(page)"
            class="min-w-[32px] h-[36px] flex items-center justify-center rounded-[8px] text-[14px] leading-[20px] transition-colors duration-300 border"
            :class="currentPage === page ? 'bg-[#FF5005] text-white border-[#FF5005]' : 'text-[#344054] hover:bg-[#F9FAFB] border-[#D0D5DD]'"
        >
          {{ page }}
        </button>
      </div>
      <button
          @click="changePage(currentPage + 1)"
          :disabled="currentPage === totalPages"
          class="px-4 py-2 rounded-[8px] text-[14px] leading-[20px] transition-colors duration-300 border"
          :class="currentPage === totalPages ? 'text-[#98A2B3] bg-[#F9FAFB] border-[#EAECF0]' : 'text-[#344054] hover:bg-[#F9FAFB] border-[#D0D5DD]'"
      >
        下一页
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
})

const currentPage = ref(1)
const itemsPerPage = 20

const totalPages = computed(() => Math.ceil(props.items.length / itemsPerPage))

const displayedItems = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage
  const end = start + itemsPerPage
  return props.items.slice(start, end)
})

const displayedPages = computed(() => {
  const pages = []
  const maxVisiblePages = 5
  let start = Math.max(1, currentPage.value - Math.floor(maxVisiblePages / 2))
  let end = Math.min(totalPages.value, start + maxVisiblePages - 1)

  if (end - start + 1 < maxVisiblePages) {
    start = Math.max(1, end - maxVisiblePages + 1)
  }

  for (let i = start; i <= end; i++) {
    pages.push(i)
  }
  return pages
})

const changePage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

const openweb = (url) => {
  window.open(url, '_blank')
}

const isNewItem = (item) => {
  const oneMonthAgo = new Date()
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
  const itemDate = new Date(item.created_at)
  return itemDate > oneMonthAgo
}

watch(() => props.items, () => {
  currentPage.value = 1
})
</script>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* 自定义滚动条样式 */
.group-hover\:opacity-100::-webkit-scrollbar {
  width: 4px;
}

.group-hover\:opacity-100::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.group-hover\:opacity-100::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

.group-hover\:opacity-100::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
}
</style>