import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "toggle-track" }
const _hoisted_4 = {
  key: 2,
  class: "absolute top-2 right-2 translate-button"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "markdown-body overflow-auto",
      innerHTML: $setup.mdParser.render($setup.displayContent)
    }, null, 8 /* PROPS */, _hoisted_2),
    ($setup.canShowTranslation && !$setup.isTranslated)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          class: "absolute top-2 right-2 translate-button",
          size: "small",
          onClick: $setup.translateContent
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              fill: "none",
              viewBox: "0 0 24 24",
              "stroke-width": "1.5",
              stroke: "currentColor",
              class: "w-4 h-4 mr-1"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                d: "m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
              })
            ], -1 /* HOISTED */),
            _createTextVNode(" AI翻译 ")
          ])),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.canShowTranslation && $setup.isTranslated)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "absolute top-2 right-2 translate-toggle",
          onClick: $setup.toggleTranslation
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["toggle-slider", { 'translate-x-[100%]': !$setup.showOriginal }])
            }, null, 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["toggle-option", { active: $setup.showOriginal, 'text-white': $setup.showOriginal }])
            }, "原文", 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["toggle-option", { active: !$setup.showOriginal, 'text-white': !$setup.showOriginal }])
            }, "译文", 2 /* CLASS */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.TRANSLATION_ENABLED && $setup.isTranslating)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_el_icon, { class: "is-loading text-[#409EFF]" }, {
            default: _withCtx(() => [
              _createVNode($setup["Loading"])
            ]),
            _: 1 /* STABLE */
          }),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "ml-2" }, "正在翻译...", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}