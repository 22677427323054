export const tags = {
  computer_vision: "Computer Vision",
  natural_language_processing: "Natural Language Processing",
  audio_processing: "Audio Processing",
  multimodal: "Multimodal",
  other: "Other",
  text_processing: "Text Processing",
  graphics: "Graphics",
  audio: "Audio",
  video: "Video",
}