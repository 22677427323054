<template>
  <div class="page-container pt-4">
    <div class="search-and-button-container mb-4 mt-6">
      <div class="inner-container">
        <div class="search-container relative">
          <input
              v-model="searchKeyword"
              type="text"
              placeholder="搜索AI工具..."
              class="w-full pl-10 pr-4 py-2 rounded-full border focus:outline-none focus:ring-2 focus:ring-[#418fff] focus:bg-gray-100 hover:bg-gray-100 transition-all duration-300 ease-in-out shadow-lg"
              @input="handleSearch"
          />
          <div class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <NavigationTabs
        :grouped-items="groupedItems"
        :current-section="currentSection"
        :is-searching="isSearching"
        @tab-click="handleTabClick"
    />

    <div class="flex items-center justify-between px-4 mb-2">
      <div class="flex-1"></div>
      <SortButton
          v-model="sortOrder"
          @toggle-sort="toggleSortOrder"
      />
    </div>


    <div class="main-content" id="pills-tabContent">
      <transition name="fade" mode="out-in">
        <div
            :key="isSearching ? 'search' : currentSection"
            class="tab-pane"
            role="tabpanel"
            tabindex="0"
        >
          <template v-if="isSearching">
            <div v-for="group in filteredGroups" :key="group.appclass">
              <h3 class="text-xl font-bold mb-4">{{ group.appclass }}</h3>
              <WebItemGrid :items="group.items" />
            </div>
          </template>
          <WebItemGrid v-else-if="currentGroup" :items="currentGroup" />
        </div>
      </transition>
    </div>
  </div>
  <AddBubble />
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import NavigationTabs from './webnav/components/NavigationTabs.vue'
import WebItemGrid from './webnav/components/WebItemGrid.vue'
import AddBubble from './webnav/components/AddBubble.vue'
import SortButton from './webnav/components/SortButton.vue'

const props = defineProps({
  webnavItems: {
    type: Array,
    default: () => []
  }
})

const items = ref([])
const currentSection = ref('')
const searchKeyword = ref('')
const isSearching = ref(false)
const sortOrder = ref('desc')

const groupOrder = {
  "AI社区": 1,
  "AI办公": 2,
  "AI写作": 3,
  "AI对话": 4,
  "AI音频": 5,
  "AI视频": 6,
  "AI编程": 7,
}

const sortItems = (itemsToSort) => {
  return [...itemsToSort].sort((a, b) => {
    const timeA = new Date(a.updated_at).getTime()
    const timeB = new Date(b.updated_at).getTime()
    return sortOrder.value === 'asc' ? timeA - timeB : timeB - timeA
  })
}

const toggleSortOrder = () => {
  sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc'
}

const groupedItems = computed(() => {
  const groups = {}
  const unknownClassOrder = new Set()

  items.value.forEach(item => {
    if (!groups[item.appclass]) {
      groups[item.appclass] = []
      if (!groupOrder[item.appclass]) {
        unknownClassOrder.add(item.appclass)
      }
    }
    groups[item.appclass].push(item)
  })

  const unknownClasses = Array.from(unknownClassOrder)
  const unknownOrder = unknownClasses.reduce((acc, className, index) => {
    acc[className] = 1000 + index
    return acc
  }, {})

  Object.keys(groups).forEach(key => {
    groups[key] = sortItems(groups[key])
  })

  return Object.entries(groups)
      .map(([appclass, items]) => ({
        appclass,
        items,
        order: groupOrder[appclass] || unknownOrder[appclass] || 999
      }))
      .sort((a, b) => a.order - b.order)
})

const currentGroup = computed(() => {
  const group = groupedItems.value.find(group => group.appclass === currentSection.value)
  return group ? group.items : null
})

const filteredGroups = computed(() => {
  if (!searchKeyword.value.trim()) {
    return groupedItems.value
  }
  const keyword = searchKeyword.value.toLowerCase().trim()
  return groupedItems.value.map(group => ({
    ...group,
    items: group.items.filter(item =>
        item.title.toLowerCase().includes(keyword) ||
        item.desc.toLowerCase().includes(keyword)
    )
  })).filter(group => group.items.length > 0)
})

const handleSearch = () => {
  isSearching.value = searchKeyword.value.trim() !== ''
}

const handleTabClick = (appclass) => {
  currentSection.value = appclass
  searchKeyword.value = ''
  isSearching.value = false
}

watch(() => props.webnavItems, (newVal) => {
  items.value = newVal
  if (items.value.length > 0 && !currentSection.value) {
    const firstGroup = groupedItems.value[0]
    currentSection.value = firstGroup ? firstGroup.appclass : ""
  }
}, { immediate: true })
</script>

<style scoped>
.web-top-menu {
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  margin-bottom: 1.5rem;
}

.web-top-menu-inner {
  padding: 1rem 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.search-container {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.search-and-button-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
}

.inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 1020px) {
  .search-and-button-container {
    padding: 0 1rem;
  }

  .inner-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-container {
    width: 100%;
  }
}
</style>