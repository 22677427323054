import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full md:w-[230px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")
  const _component_el_menu = _resolveComponent("el-menu")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      "default-active": $setup.activeIndex,
      class: "xty-menu md:!h-[44px] md:justify-around !border-b-0",
      "menu-trigger": $setup.isMobile ? 'click' : 'hover',
      mode: "horizontal",
      "ellipsis-icon": $setup.MoreFilled,
      "active-text-color": "#FF5005",
      "text-color": "#000000e0",
      style: {"--el-menu-hover-bg-color":"#FF500530","--el-menu-hover-text-color":"#FF5005","--el-menu-bg-color":"transparent"},
      onSelect: $setup.handleSelect
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.processedItems, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.index
          }, [
            (item.subs)
              ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                  key: 0,
                  index: item.index
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subs, (sub) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: sub.index
                      }, [
                        _createCommentVNode(" 三级菜单 "),
                        (sub.subs)
                          ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                              key: 0,
                              index: sub.index
                            }, {
                              title: _withCtx(() => [
                                _createTextVNode(_toDisplayString(sub.title), 1 /* TEXT */)
                              ]),
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sub.subs, (ssub) => {
                                  return (_openBlock(), _createBlock(_component_el_menu_item, {
                                    key: ssub.index,
                                    index: ssub.index,
                                    disabled: ssub.nonclickable,
                                    style: {"--el-menu-hover-bg-color":"#FF500530","--el-menu-hover-text-color":"#FF5005"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(ssub.title), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index", "disabled"]))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createCommentVNode(" 二级菜单 "),
                              _createVNode(_component_el_menu_item, {
                                index: sub.index,
                                disabled: sub.nonclickable,
                                style: {"--el-menu-hover-bg-color":"#FF500530","--el-menu-hover-text-color":"#FF5005"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(sub.title), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index", "disabled"])
                            ], 64 /* STABLE_FRAGMENT */))
                      ], 64 /* STABLE_FRAGMENT */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index"]))
              : (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: 1,
                  index: item.index,
                  disabled: item.nonclickable
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index", "disabled"]))
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["default-active", "menu-trigger", "ellipsis-icon"])
  ]))
}