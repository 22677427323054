<template>
  <div class="bg-white min-h-screen">
    <div class="max-w-5xl mx-auto px-4 py-8">
      <div class="mb-6 flex justify-between items-center">
        <button @click="$router.back()" class="flex items-center text-gray-600 hover:text-gray-900">
          <el-icon class="mr-1"><ArrowLeft /></el-icon>
          返回
        </button>
        <div v-if="canEdit" class="flex items-center gap-3">
          <a 
            :href="'/event_conclusions/' + eventConclusion.id + '/edit'"
            class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <el-icon class="mr-1.5"><Edit /></el-icon>
            编辑总结
          </a>
          <el-button 
            type="danger" 
            @click="handleDelete"
            class="!h-[36px]"
          >
            <el-icon class="mr-1.5"><Delete /></el-icon>
            删除总结
          </el-button>
        </div>
      </div>

      <div class="bg-white rounded-lg border border-gray-200 p-6">
        <div class="mb-8">
          <h1 class="text-2xl font-semibold text-gray-900 mb-4">{{ eventConclusion.title }}</h1>
          
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 class="text-sm font-medium text-gray-500 mb-1">主办单位</h3>
              <p class="text-base text-gray-900">{{ eventConclusion.event_leader }}</p>
            </div>
            <div>
              <h3 class="text-sm font-medium text-gray-500 mb-1">活动时间</h3>
              <p class="text-base text-gray-900">{{ formatDate(eventConclusion.event_date) }}</p>
            </div>
            <div>
              <h3 class="text-sm font-medium text-gray-500 mb-1">活动地点</h3>
              <p class="text-base text-gray-900">{{ eventConclusion.event_location }}</p>
            </div>
          </div>
        </div>

        <div class="space-y-6">
          <div>
            <h3 class="text-sm font-medium text-gray-500 mb-2">背景情况</h3>
            <div class="prose max-w-none">
              {{ eventConclusion.background }}
            </div>
          </div>

          <div>
            <h3 class="text-sm font-medium text-gray-500 mb-2">活动情况</h3>
            <div class="prose max-w-none">
              {{ eventConclusion.conclusion }}
            </div>
          </div>

          <div>
            <h3 class="text-sm font-medium text-gray-500 mb-2">活动成效</h3>
            <div class="prose max-w-none">
              {{ eventConclusion.effect }}
            </div>
          </div>

          <!-- 附件 -->
          <div v-if="eventConclusion.attachments?.length">
            <h2 class="text-xl font-bold mb-3">相关附件</h2>
            <div class="bg-gray-50 rounded-lg p-4">
              <div v-for="attachment in eventConclusion.attachments" :key="attachment.url" class="mb-2 last:mb-0">
                <a
                    :href="attachment.url"
                    target="_blank"
                    class="inline-flex items-center text-blue-600 hover:text-blue-800"
                >
                  <el-icon class="mr-1"><Document /></el-icon>
                  <span>{{ attachment.original_filename }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ArrowLeft, Edit, Delete, Document} from '@element-plus/icons-vue'
import { computed } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { deleteEventConclusion } from '@api/csrf/event_conclusion'

const props = defineProps({
  eventConclusion: {
    type: Object,
    required: true
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isEventHolder: {
    type: Boolean,
    default: false
  },
  currentUserId: {
    type: [Number, String],
    required: true
  }
})

const canEdit = computed(() => {
  return props.isEventHolder && props.eventConclusion.user_id === props.currentUserId
})

const formatDate = (date) => {
  if (!date) return ''
  const d = new Date(date)
  return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
}

const handleDelete = async () => {
  try {
    await ElMessageBox.confirm('确定要删除这个活动总结吗？此操作不可恢复。', '删除确认', {
      confirmButtonText: '确定删除',
      cancelButtonText: '取消',
      type: 'warning'
    })

    await deleteEventConclusion(props.eventConclusion.id)
    ElMessage({
      message: '活动总结已删除',
      type: 'success',
      duration: 500,
      onClose: () => {
        window.location.href = '/event_conclusions'
      }
    })
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error(error.message || '删除失败')
    }
  }
}
</script> 