<template>
  <div class="max-w-[1440px] m-auto px-4 py-6">
    <div class="mb-6 flex items-center">
      <a href="/event_conclusions" class="inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200">
        <el-icon class="mr-1"><ArrowLeft /></el-icon>
        返回总结列表
      </a>
    </div>

    <div class="flex items-center justify-center mb-[100px] md:mb-[50px] mt-[50px] lg:mt-[50px]">
      <div class="flex flex-col bg-white w-[766px] lg:w-[566px] md:w-[90%] h-auto border rounded-lg p-[36px] border-[#DCDFE6] shadow-xl">
        <h1 class="text-2xl font-bold mb-8 text-center">{{ isEdit ? '编辑活动总结' : '新建活动总结' }}</h1>

        <!-- 模板下载 -->
        <div class="mb-8">
          <div class="text-[14px] text-[#606266] mb-2 flex items-center">
            <el-icon class="mr-1 text-[#E6A23C]"><Warning /></el-icon>
            请下载模板，并参考模板提示，如实填写相关信息
          </div>
          <div class="flex items-center justify-between p-4 bg-[#F8F9FA] rounded-lg border border-[#E4E7ED]">
            <div class="flex items-center">
              <el-icon class="text-[20px] text-[#3250BD] mr-2"><Document /></el-icon>
              <span class="text-[14px] text-[#1D2129]">{{ templateName }}</span>
            </div>
            <el-button
                type="primary"
                link
                class="!text-[14px]"
                @click="handleTemplateDownload"
            >
              <el-icon class="mr-1"><Download /></el-icon>
              下载模板
            </el-button>
          </div>
        </div>

        <el-form
            ref="formRef"
            :model="formData"
            :rules="rules"
            label-position="top"
        >
          <!-- 标题 -->
          <el-form-item label="标题" prop="title">
            <el-input v-model="formData.title" placeholder="请输入标题" />
          </el-form-item>

          <!-- 活动基本信息 -->
          <div class="form-section">
            <h3 class="text-lg font-medium mb-4">活动基本信息</h3>
            <el-form-item label="主办单位" prop="event_leader">
              <el-input v-model="formData.event_leader" placeholder="请输入主办单位" />
            </el-form-item>

            <el-form-item label="活动时间" prop="event_date">
              <el-date-picker
                  v-model="formData.event_date"
                  type="date"
                  placeholder="选择活动时间"
                  style="width: 100%"
              />
            </el-form-item>

            <el-form-item label="活动地点" prop="event_location">
              <el-input v-model="formData.event_location" placeholder="请输入活动地点" />
            </el-form-item>
          </div>

          <!-- 背景情况 -->
          <div class="form-section">
            <el-form-item label="背景情况" prop="background">
              <el-input
                  v-model="formData.background"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入背景情况"
              />
            </el-form-item>
          </div>

          <!-- 活动情况 -->
          <div class="form-section">
            <el-form-item label="活动情况" prop="conclusion">
              <el-input
                  v-model="formData.conclusion"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入活动情况"
              />
            </el-form-item>
          </div>

          <!-- 活动成效 -->
          <div class="form-section">
            <el-form-item label="活动成效" prop="effect">
              <el-input
                  v-model="formData.effect"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入活动成效"
              />
            </el-form-item>
          </div>

          <!-- 下一步计划 -->
          <div class="form-section">
            <el-form-item label="下一步计划" prop="next_step">
              <el-input
                  v-model="formData.next_step"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入下一步计划"
              />
            </el-form-item>
          </div>

          <!-- 附件上传 -->
          <div class="form-section">
            <h3 class="text-lg font-medium mb-4">附件上传</h3>
            <el-form-item label="相关附件">
              <el-upload
                  class="upload-demo"
                  :action="'/internal_api/upload'"
                  :headers="uploadHeaders"
                  :on-success="handleUploadSuccess"
                  :on-error="handleUploadError"
                  :before-upload="beforeUpload"
                  :file-list="fileList"
                  :on-remove="handleFileRemove"
                  multiple
              >
                <el-button type="primary">点击上传</el-button>
                <template #tip>
                  <div class="el-upload__tip text-[#666] mt-2">
                    支持 doc、docx、pdf、jpg、png 格式文件，单个文件不超过10MB
                  </div>
                </template>
              </el-upload>
            </el-form-item>
          </div>

          <el-form-item class="flex justify-end gap-4">
            <el-button
                @click="handleCancel"
                class="!h-[48px] !text-[16px]"
            >
              取消
            </el-button>
            <el-button
                type="primary"
                class="!text-center !h-[48px] !text-[16px] !text-white !bg-[#3250BD] !rounded-[8px] !border-[1px] !border-[#3250BD]"
                @click="handleSubmit"
            >
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { ArrowLeft, Upload, Download, Document, Warning } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { createEventConclusion, updateEventConclusion } from '@api/csrf/event_conclusion'

const props = defineProps({
  eventConclusion: {
    type: Object,
    default: null
  }
})

// 添加获取CSRF token的函数
const getCSRFToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]')
  return token ? token.getAttribute('content') : ''
}

const formatDate = (date) => {
  if (!date) return ''
  const d = new Date(date)
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
}

// 添加headers配置
const uploadHeaders = {
  'X-CSRF-Token': getCSRFToken()
}

const isEdit = computed(() => !!props.eventConclusion)

const formRef = ref(null)
const formData = ref({
  title: '',
  event_leader: '',
  event_date: '',
  event_location: '',
  background: '',
  conclusion: '',
  effect: '',
  next_step: '',
  attachments: []
})

// 上传相关
const fileList = ref([])

// 初始化数据
onMounted(() => {
  if (props.eventConclusion) {
    formData.value = {
      title: props.eventConclusion.title,
      event_leader: props.eventConclusion.event_leader,
      event_date: props.eventConclusion.event_date ? new Date(props.eventConclusion.event_date) : '',
      event_location: props.eventConclusion.event_location,
      background: props.eventConclusion.background,
      conclusion: props.eventConclusion.conclusion,
      effect: props.eventConclusion.effect,
      next_step: props.eventConclusion.next_step,
      attachments: props.eventConclusion.attachments || []
    }

    // 如果有附件，初始化文件列表
    if (props.eventConclusion.attachments?.length) {
      fileList.value = props.eventConclusion.attachments.map(attachment => ({
        name: attachment.original_filename,
        url: attachment.url
      }))
    }
  }
})

const beforeUpload = (file) => {
  const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png']
  const maxSize = 10 * 1024 * 1024 // 10MB

  if (!allowedTypes.includes(file.type)) {
    ElMessage.error('只支持 doc、docx、pdf、jpg、png 格式文件')
    return false
  }

  if (file.size > maxSize) {
    ElMessage.error('文件大小不能超过 10MB')
    return false
  }

  return true
}

const handleUploadSuccess = (response, uploadFile) => {
  const newAttachment = {
    url: response.url,
    original_filename: response.original_filename || uploadFile.name
  }
  formData.value.attachments.push(newAttachment)
  fileList.value.push({
    name: newAttachment.original_filename,
    url: newAttachment.url
  })
  ElMessage.success('文件上传成功')
}

const handleUploadError = () => {
  ElMessage.error('文件上传失败')
}

const handleFileRemove = (file) => {
  // 从 formData 的 attachments 中移除对应的附件
  const index = formData.value.attachments.findIndex(
      attachment => attachment.url === file.url
  )
  if (index > -1) {
    formData.value.attachments.splice(index, 1)
  }
  // fileList 会由 el-upload 组件自动处理
}

const rules = {
  title: [
    { required: true, message: '请输入标题', trigger: 'blur' },
    { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
  ],
  event_leader: [
    { required: true, message: '请输入主办单位', trigger: 'blur' }
  ],
  event_date: [
    { required: true, message: '请选择活动时间', trigger: 'change' }
  ],
  event_location: [
    { required: true, message: '请输入活动地点', trigger: 'blur' }
  ],
  background: [
    { required: true, message: '请输入背景情况', trigger: 'blur' },
    { min: 10, max: 2000, message: '长度在 10 到 2000 个字符', trigger: 'blur' }
  ],
  conclusion: [
    { required: true, message: '请输入活动情况', trigger: 'blur' },
    { min: 10, max: 2000, message: '长度在 10 到 2000 个字符', trigger: 'blur' }
  ],
  effect: [
    { required: true, message: '请输入活动成效', trigger: 'blur' },
    { min: 10, max: 2000, message: '长度在 10 到 2000 个字符', trigger: 'blur' }
  ],
  next_step: [
    { required: true, message: '请输入下一步计划', trigger: 'blur' },
    { min: 10, max: 2000, message: '长度在 10 到 2000 个字符', trigger: 'blur' }
  ],
  attachments: [
    { required: true, message: '请上传活动总结', trigger: 'change' }
  ]
}

const handleCancel = async () => {
  try {
    await ElMessageBox.confirm('确定要取消编辑吗？未保存的内容将会丢失', '确认取消', {
      confirmButtonText: '确定',
      cancelButtonText: '继续编辑',
      type: 'warning'
    })
    window.location.href = '/event_conclusions'
  } catch {
    // 用户选择继续编辑，不做任何操作
  }
}

const handleSubmit = async () => {
  if (!formRef.value) return

  try {
    await formRef.value.validate()

    // 只提取需要的字段
    const submitData = {
      title: formData.value.title,
      event_leader: formData.value.event_leader,
      event_date: formatDate(formData.value.event_date),
      event_location: formData.value.event_location,
      background: formData.value.background,
      conclusion: formData.value.conclusion,
      effect: formData.value.effect,
      next_step: formData.value.next_step,
      attachments: formData.value.attachments
    }

    // 移除空字段
    Object.keys(submitData).forEach(key => {
      if (submitData[key] === '') {
        delete submitData[key]
      }
    })

    if (isEdit.value) {
      await updateEventConclusion(props.eventConclusion.id, {
        event_conclusion: submitData
      })
      ElMessage.success('活动总结已更新')
    } else {
      await createEventConclusion({
        event_conclusion: submitData
      })
      ElMessage.success('活动总结已创建')
    }

    window.location.href = '/event_conclusions'
  } catch (error) {
    if (error.response?.data?.errors) {
      ElMessage.error(error.response.data.errors.join(', '))
    } else if (error.message) {
      ElMessage.error(error.message)
    } else {
      ElMessage.error('保存失败，请检查表单内容')
    }
  }
}

const templateUrl = 'http://caict-llm-portal-storage-test.oss-cn-beijing.aliyuncs.com/6a0cb4fe-c6c9-429e-998b-d8e985919459'
const templateName = '人工智能赋能新型工业化供需对接深度行活动总结报告（模板）'

const handleTemplateDownload = () => {
  if (!templateUrl) {
    ElMessage.warning('模板下载地址未配置')
    return
  }

  // 创建一个临时的a标签来触发下载
  const link = document.createElement('a')
  link.href = templateUrl
  link.download = templateName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
</script>

<style scoped>
.form-section {
  margin-bottom: 2rem;
}

.form-section:last-child {
  margin-bottom: 1rem;
}
</style> 