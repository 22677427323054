import axios from 'axios'
import { ElMessage } from 'element-plus'

// 创建实例
const service = axios.create({
  baseURL: location.origin, // url = base url + request url
  timeout: 50000 // 请求超时
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    config.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    return config
  },
  error => {
    // 请求错误，可在此进行统一错误处理
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const { data } = response
    if (data.code == 500) {
      ElMessage({
        message: data.message,
        type: 'error'
      })
      return Promise.reject(new Error(data.message))
    } else if (data.code && ![200, 201, 202, 204].includes(data.code)) {
      const msg = data.message || 'Error'
      return Promise.reject(new Error(msg))
    } else {
      return data
    }
  },
  error => {
    console.log('err' + error) // for debug
    const message = error.response.data.message
    if (message) {
      ElMessage({
        message: data.message,
        type: 'error'
      })
    }
    return Promise.reject(error)
  }
)

export default service
