import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0" }
const _hoisted_2 = { class: "pt-[32px] w-full xl:pr-[20px] mlg:px-[20px]" }
const _hoisted_3 = {
  key: 0,
  class: "mb-8"
}
const _hoisted_4 = { class: "flex xl:flex-col justify-between px-[16px] mb-4" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  href: "/event_conclusions/new",
  class: "inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
}
const _hoisted_7 = { class: "grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-4 mb-4 mt-[16px] xl:pl-[20px] mlg:pl-0" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex xl:flex-col justify-between px-[16px] mb-4" }
const _hoisted_10 = { class: "flex items-center gap-4" }
const _hoisted_11 = { class: "px-[16px] mb-2" }
const _hoisted_12 = { class: "bg-white rounded-lg border border-[#EAECF0] overflow-hidden" }
const _hoisted_13 = { class: "text-sm text-gray-900 truncate" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "text-sm text-gray-500 truncate" }
const _hoisted_16 = { class: "text-sm text-gray-500 whitespace-nowrap" }
const _hoisted_17 = { class: "text-sm text-gray-500 truncate" }
const _hoisted_18 = { class: "text-sm text-gray-500 whitespace-nowrap" }
const _hoisted_19 = { class: "text-right" }
const _hoisted_20 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.isEventHolder)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "text-lg font-normal text-[#101828] flex items-center gap-2" }, [
                _createElementVNode("span", null, "我的活动总结")
              ], -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("a", _hoisted_6, [
                  _createVNode(_component_el_icon, { class: "mr-1" }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Plus"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _cache[1] || (_cache[1] = _createTextVNode(" 新建总结 "))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.eventConclusions, (conclusion) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: conclusion.id
                }, [
                  _createVNode($setup["EventConclusionCard"], { conclusion: conclusion }, null, 8 /* PROPS */, ["conclusion"])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]))
        : _createCommentVNode("v-if", true),
      ($props.isAdmin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-lg font-normal text-[#101828] flex items-center gap-2" }, [
                _createElementVNode("span", null, "活动总结报告查看")
              ], -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_el_date_picker, {
                  modelValue: $setup.dateRange,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dateRange) = $event)),
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  format: "YYYY-MM-DD",
                  "value-format": "YYYY-MM-DD",
                  onChange: $setup.handleDateRangeChange,
                  class: "!w-[360px]"
                }, null, 8 /* PROPS */, ["modelValue"]),
                ($setup.hasDateFilter)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      size: "default",
                      class: "!h-[32px]",
                      onClick: $setup.resetDateRange
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" 清除筛选 ")
                      ])),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_el_button, {
                  type: "primary",
                  size: "default",
                  class: "!h-[32px]",
                  onClick: $setup.handleExport
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" 导出表格 ")
                  ])),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"grid grid-cols-[3fr,2fr,180px,180px,180px,100px] gap-4 px-6 py-3 bg-gray-50 border-b border-[#EAECF0]\"><div class=\"text-sm font-medium text-gray-500\">标题</div><div class=\"text-sm font-medium text-gray-500\">主办单位</div><div class=\"text-sm font-medium text-gray-500\">活动时间</div><div class=\"text-sm font-medium text-gray-500\">活动地点</div><div class=\"text-sm font-medium text-gray-500\">更新时间</div><div class=\"text-sm font-medium text-gray-500 text-right\">详情</div></div>", 1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.adminEventConclusions, (conclusion) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: conclusion.id,
                    class: "grid grid-cols-[3fr,2fr,180px,180px,180px,100px] gap-4 px-6 py-4 border-b border-[#EAECF0] hover:bg-gray-50"
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("a", {
                        href: '/event_conclusions/' + conclusion.id,
                        class: "hover:text-blue-600"
                      }, _toDisplayString(conclusion.title), 9 /* TEXT, PROPS */, _hoisted_14)
                    ]),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(conclusion.event_leader), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_16, _toDisplayString($setup.formatDate(conclusion.event_date)), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(conclusion.event_location), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_18, _toDisplayString($setup.formatDateTime(conclusion.updated_at)), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("a", {
                        href: '/event_conclusions/' + conclusion.id,
                        class: "text-sm text-gray-500 hover:text-blue-600"
                      }, "查看详情", 8 /* PROPS */, _hoisted_20)
                    ])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}