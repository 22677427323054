<template>
  <div class="flex md:justify-between community-border-b md:gap-[unset] gap-[16px]">
    <div class="md:px-[0px] px-[16px] py-[12px] w-[168px] css-custom-input">
      <el-date-picker
        type="month"
        v-model="searchForm.version"
        placeholder="选择月份"
        format="YYYY年MM月"
        clear-icon=""
        @change="queryByVersion"
      />
    </div>
    <div class="md:px-[0px] px-[16px] py-[12px] w-[168px] css-custom-input">
      <el-select
        v-model="localSearchForm.deployment_methods"
        multiple
        collapse-tags
        collapse-tags-tooltip
        placeholder="部署方式"
        style="width: 168px"
        @change="handleFilterChange"
      >
        <el-option
          v-for="item in deployment_method_options"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <div class="md:hidden px-[16px] py-[12px] w-[168px] css-custom-input">
      <el-select
        v-model="localSearchForm.input_modals"
        multiple
        collapse-tags
        collapse-tags-tooltip
        placeholder="输入模态种类"
        style="width: 168px"
        @change="handleFilterChange"
      >
        <el-option
          v-for="item in input_modal_options"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <div class="md:hidden px-[16px] py-[12px] w-[168px] css-custom-input">
      <el-select
        v-model="localSearchForm.output_modals"
        multiple
        collapse-tags
        collapse-tags-tooltip
        placeholder="输出模态种类"
        style="width: 168px"
        @change="handleFilterChange"
      >
        <el-option
          v-for="item in output_modal_options"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <div class="flex items-center ml-4">
      <el-button type="primary" @click="$emit('adjustWeight')">
        调整权重
      </el-button>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from 'vue'
import dayjs from 'dayjs'

export default {
  name: 'RankFilter',
  
  props: {
    version: {
      type: String,
      required: true,
      default: ''
    },
    deployment_method_options: {
      type: Set,
      required: true,
      default: () => new Set()
    },
    input_modal_options: {
      type: Set,
      required: true,
      default: () => new Set()
    },
    output_modal_options: {
      type: Set,
      required: true,
      default: () => new Set()
    }
  },

  emits: ['filterChange', 'adjustWeight'],

  setup(props, { emit }) {
    const searchForm = reactive({
      version: props.version || ''
    })

    const localSearchForm = reactive({
      deployment_methods: [],
      input_modals: [],
      output_modals: []
    })

    // 监听 props.version 的变化
    watch(() => props.version, (newVersion) => {
      searchForm.version = newVersion || ''
    })

    const queryByVersion = () => {
      if (!searchForm.version) return
      window.location.href = `?version=${dayjs(searchForm.version).format("YYYY-MM-DD")}`
    }

    const handleFilterChange = () => {
      emit('filterChange', localSearchForm)
    }

    return {
      searchForm,
      localSearchForm,
      queryByVersion,
      handleFilterChange
    }
  }
}
</script>

<style scoped>
.community-border-b {
  @apply border-b border-[#E5E6E8];
}
</style> 