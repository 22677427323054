import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sort-button-wrapper" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "sort-text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('toggle-sort'))),
      class: _normalizeClass(["sort-button", { 'sort-button-active': $props.modelValue === 'asc' }]),
      title: $props.modelValue === 'asc' ? '最早更新' : '最新更新'
    }, [
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.modelValue === 'asc' ? '最早' : '最新'), 1 /* TEXT */),
      (_openBlock(), _createElementBlock("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20 20",
        fill: "currentColor",
        class: _normalizeClass(["sort-icon", { 'rotate-180': $props.modelValue === 'desc' }])
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("path", {
          "fill-rule": "evenodd",
          d: "M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z",
          "clip-rule": "evenodd"
        }, null, -1 /* HOISTED */)
      ]), 2 /* CLASS */))
    ], 10 /* CLASS, PROPS */, _hoisted_2)
  ]))
}