import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 px-4 py-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "p-3 h-full flex flex-col" }
const _hoisted_4 = { class: "flex items-center space-x-2 mb-2" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "text-[rgba(0,0,0,0.88)] text-[14px] font-[400] leading-[20px] tracking-[0.014px] truncate flex-grow" }
const _hoisted_7 = { class: "text-[#475467] text-[12px] leading-[18px] line-clamp-2 flex-grow" }
const _hoisted_8 = {
  key: 0,
  class: "absolute top-0 right-0 bg-[#FF5005] text-white px-2 py-1 text-xs font-semibold rounded-bl-lg"
}
const _hoisted_9 = { class: "absolute inset-0 bg-[#101828] bg-opacity-90 text-white p-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 overflow-y-auto" }
const _hoisted_10 = { class: "text-[13px] leading-[20px] tracking-[0.014px]" }
const _hoisted_11 = {
  key: 0,
  class: "flex justify-center items-center space-x-2 mt-6 mb-8"
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "flex items-center space-x-1" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayedItems, (item, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: idx,
          class: "bg-white border border-[#E6E6E6] rounded-[8px] shadow-[0_0_0_0_rgba(0,0,0,0.05)] hover:border-[#0C266E] overflow-hidden cursor-pointer transition-all duration-300 relative group",
          onClick: $event => ($setup.openweb(item.url))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: item.logo,
                alt: item.title,
                class: "w-8 h-8 rounded-[4px] object-cover flex-shrink-0"
              }, null, 8 /* PROPS */, _hoisted_5),
              _createElementVNode("h4", _hoisted_6, _toDisplayString(item.title), 1 /* TEXT */)
            ]),
            _createElementVNode("p", _hoisted_7, _toDisplayString(item.desc), 1 /* TEXT */)
          ]),
          ($setup.isNewItem(item))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, " 新 "))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(item.desc), 1 /* TEXT */)
          ])
        ], 8 /* PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" 分页控件 "),
    ($setup.totalPages > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = $event => ($setup.changePage($setup.currentPage - 1))),
            disabled: $setup.currentPage === 1,
            class: _normalizeClass(["px-4 py-2 rounded-[8px] text-[14px] leading-[20px] transition-colors duration-300 border", $setup.currentPage === 1 ? 'text-[#98A2B3] bg-[#F9FAFB] border-[#EAECF0]' : 'text-[#344054] hover:bg-[#F9FAFB] border-[#D0D5DD]'])
          }, " 上一页 ", 10 /* CLASS, PROPS */, _hoisted_12),
          _createElementVNode("div", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayedPages, (page) => {
              return (_openBlock(), _createElementBlock("button", {
                key: page,
                onClick: $event => ($setup.changePage(page)),
                class: _normalizeClass(["min-w-[32px] h-[36px] flex items-center justify-center rounded-[8px] text-[14px] leading-[20px] transition-colors duration-300 border", $setup.currentPage === page ? 'bg-[#FF5005] text-white border-[#FF5005]' : 'text-[#344054] hover:bg-[#F9FAFB] border-[#D0D5DD]'])
              }, _toDisplayString(page), 11 /* TEXT, CLASS, PROPS */, _hoisted_14))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = $event => ($setup.changePage($setup.currentPage + 1))),
            disabled: $setup.currentPage === $setup.totalPages,
            class: _normalizeClass(["px-4 py-2 rounded-[8px] text-[14px] leading-[20px] transition-colors duration-300 border", $setup.currentPage === $setup.totalPages ? 'text-[#98A2B3] bg-[#F9FAFB] border-[#EAECF0]' : 'text-[#344054] hover:bg-[#F9FAFB] border-[#D0D5DD]'])
          }, " 下一页 ", 10 /* CLASS, PROPS */, _hoisted_15)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}