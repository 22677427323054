<template>
  <h2 class="text-[28px] font-[600] leading-[28px] text-center md:w-[343px] mb-[20px] m-auto">
    模型评测
    <span class="ml-[8px] px-[8px] absolute rounded-[8px] bg-[#E3EDF8] text-[#678ECE] text-[12px] font-[500]">
      即将上线
    </span>
  </h2>
  <div class="flex flex-col items-center">
    <div class="text-[20px] font-[300] leading-[30px] text-[#475467] mb-[64px] text-center w-[758px] md:w-full">
      赋能技术创新、帮助模型选型、防范安全风险
    </div>

    <div class="flex md:flex-col gap-[20px] w-full">
      <div class="rounded-[20px] w-full bg-white" v-for="card in cards" :key="card.title">
        <div class="rounded-[20px_20px_0_0] bg-cover text-[20px] px-[30px] font-[400] py-[32px] leading-[30px] opacity-90 bg-[url('images/xty_home/model_system_card_bg.svg')] relative">
          {{ card.title }}
          <img :src="card.header_icon" class="w-[140px] h-[140px] absolute right-[0px] top-[-40px]" alt="">
        </div>
        <div class="mt-[24px] px-[30px] py-[32px]">
          <div class="flex gap-[16px] flex-col">
            <div class="flex gap-[12px] items-center" v-for="task in card.tasks" :key="task.icon">
              <img :src="task.icon" class="w-[32px] h-[32px]" alt="">
              <div class="text-[18px] font-[400] leading-[23px] opacity-80">
                {{ task.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        cards: [
          {
            title: '中立性的统一基准',
            header_icon: '/images/xty_home/model_system_card_header_icon1.svg',
            tasks: [
              {
                icon: '/images/xty_home/model_system_1.svg',
                text: '全维度'
              },
              {
                icon: '/images/xty_home/model_system_2.svg',
                text: '可拓展'
              },
              {
                icon: '/images/xty_home/model_system_3.svg',
                text: '公开、公平、公正'
              }
            ]
          },
          {
            title: '深度挖掘应用需求',
            header_icon: '/images/xty_home/model_system_card_header_icon2.svg',
            tasks: [
              {
                icon: '/images/xty_home/model_system_4.svg',
                text: '围绕实际需求'
              },
              {
                icon: '/images/xty_home/model_system_5.svg',
                text: '场景能力抽象'
              },
              {
                icon: '/images/xty_home/model_system_6.svg',
                text: '动态评测'
              }
            ]
          },
          {
            title: '应用落地的配套服务',
            header_icon: '/images/xty_home/model_system_card_header_icon3.svg',
            tasks: [
              {
                icon: '/images/xty_home/model_system_7.svg',
                text: '广泛连接企业'
              },
              {
                icon: '/images/xty_home/model_system_8.svg',
                text: '模型选型服务'
              },
              {
                icon: '/images/xty_home/model_system_9.svg',
                text: '精准供需对接'
              }
            ]
          }
        ]
      }
    }
  }
</script>

