import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white min-h-screen" }
const _hoisted_2 = { class: "max-w-5xl mx-auto px-4 py-8" }
const _hoisted_3 = { class: "mb-6 flex justify-between items-center" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center gap-3"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "bg-white rounded-lg border border-gray-200 p-6" }
const _hoisted_7 = { class: "mb-8" }
const _hoisted_8 = { class: "text-2xl font-semibold text-gray-900 mb-4" }
const _hoisted_9 = { class: "grid grid-cols-1 md:grid-cols-2 gap-6" }
const _hoisted_10 = { class: "text-base text-gray-900" }
const _hoisted_11 = { class: "text-base text-gray-900" }
const _hoisted_12 = { class: "text-base text-gray-900" }
const _hoisted_13 = { class: "space-y-6" }
const _hoisted_14 = { class: "prose max-w-none" }
const _hoisted_15 = { class: "prose max-w-none" }
const _hoisted_16 = { class: "prose max-w-none" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "bg-gray-50 rounded-lg p-4" }
const _hoisted_19 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.$router.back())),
          class: "flex items-center text-gray-600 hover:text-gray-900"
        }, [
          _createVNode(_component_el_icon, { class: "mr-1" }, {
            default: _withCtx(() => [
              _createVNode($setup["ArrowLeft"])
            ]),
            _: 1 /* STABLE */
          }),
          _cache[1] || (_cache[1] = _createTextVNode(" 返回 "))
        ]),
        ($setup.canEdit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                href: '/event_conclusions/' + $props.eventConclusion.id + '/edit',
                class: "inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              }, [
                _createVNode(_component_el_icon, { class: "mr-1.5" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["Edit"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _cache[2] || (_cache[2] = _createTextVNode(" 编辑总结 "))
              ], 8 /* PROPS */, _hoisted_5),
              _createVNode(_component_el_button, {
                type: "danger",
                onClick: $setup.handleDelete,
                class: "!h-[36px]"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, { class: "mr-1.5" }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Delete"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _cache[3] || (_cache[3] = _createTextVNode(" 删除总结 "))
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h1", _hoisted_8, _toDisplayString($props.eventConclusion.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", null, [
              _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "text-sm font-medium text-gray-500 mb-1" }, "主办单位", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_10, _toDisplayString($props.eventConclusion.event_leader), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-sm font-medium text-gray-500 mb-1" }, "活动时间", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_11, _toDisplayString($setup.formatDate($props.eventConclusion.event_date)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-sm font-medium text-gray-500 mb-1" }, "活动地点", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_12, _toDisplayString($props.eventConclusion.event_location), 1 /* TEXT */)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", null, [
            _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "text-sm font-medium text-gray-500 mb-2" }, "背景情况", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_14, _toDisplayString($props.eventConclusion.background), 1 /* TEXT */)
          ]),
          _createElementVNode("div", null, [
            _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "text-sm font-medium text-gray-500 mb-2" }, "活动情况", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_15, _toDisplayString($props.eventConclusion.conclusion), 1 /* TEXT */)
          ]),
          _createElementVNode("div", null, [
            _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-sm font-medium text-gray-500 mb-2" }, "活动成效", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_16, _toDisplayString($props.eventConclusion.effect), 1 /* TEXT */)
          ]),
          _createCommentVNode(" 附件 "),
          ($props.eventConclusion.attachments?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "text-xl font-bold mb-3" }, "相关附件", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_18, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.eventConclusion.attachments, (attachment) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: attachment.url,
                      class: "mb-2 last:mb-0"
                    }, [
                      _createElementVNode("a", {
                        href: attachment.url,
                        target: "_blank",
                        class: "inline-flex items-center text-blue-600 hover:text-blue-800"
                      }, [
                        _createVNode(_component_el_icon, { class: "mr-1" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["Document"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createElementVNode("span", null, _toDisplayString(attachment.original_filename), 1 /* TEXT */)
                      ], 8 /* PROPS */, _hoisted_19)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ])
  ]))
}