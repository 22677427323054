<template>
  <div class="relative">
    <div class="markdown-body overflow-auto" v-html="mdParser.render(displayContent)"></div>
    <el-button 
      v-if="canShowTranslation && !isTranslated" 
      class="absolute top-2 right-2 translate-button" 
      size="small" 
      @click="translateContent"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
        <path stroke-linecap="round" stroke-linejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
      </svg>
      AI翻译
    </el-button>
    <div 
      v-if="canShowTranslation && isTranslated" 
      class="absolute top-2 right-2 translate-toggle"
      @click="toggleTranslation"
    >
      <div class="toggle-track">
        <div class="toggle-slider" :class="{ 'translate-x-[100%]': !showOriginal }"></div>
        <div class="toggle-option" :class="{ active: showOriginal, 'text-white': showOriginal }">原文</div>
        <div class="toggle-option" :class="{ active: !showOriginal, 'text-white': !showOriginal }">译文</div>
      </div>
    </div>
    <div v-if="TRANSLATION_ENABLED && isTranslating" class="absolute top-2 right-2 translate-button">
      <el-icon class="is-loading text-[#409EFF]"><Loading /></el-icon>
      <span class="ml-2">正在翻译...</span>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import MarkdownIt from 'markdown-it';
  import markdownItAnchor from 'markdown-it-anchor'
  import parseMD from 'parse-md'
  import hljs from 'highlight.js'
  import 'highlight.js/styles/atom-one-light.css'
  import '@github/clipboard-copy-element'
  import tippy from 'tippy.js'
  import 'tippy.js/dist/tippy.css'
  import { useI18n } from 'vue-i18n'
  import { ElMessage } from 'element-plus'
  import { Loading } from '@element-plus/icons-vue'
  import { translate } from '@api/csrf/translate'
  import Cookies from 'js-cookie'

  const TRANSLATION_ENABLED = true
  const MIN_CONTENT_LENGTH = 5

  const { t } = useI18n();
  
  const props = defineProps({
    content: {
      type: String,
      default: ''
    },
    setDefaultText: {
      type: Boolean,
      default: false
    },
    isReadme: {
      type: Boolean,
      default: false
    }
  })

  const defaultText = computed(() => t('all.defaultText'))
  const isTranslating = ref(false)
  const isTranslated = ref(false)
  const showOriginal = ref(true)
  const translatedContent = ref('')

  const isLoggedIn = () => {
    return !!Cookies.get('login_identity')
  }

  const parsedContent = computed(() => {
    try {
      const { content } = parseMD(props.content || '')
      return content.trim()
    } catch (error) {
      console.error('Error parsing markdown content:', error)
      return props.content || ''
    }
  })

  const isDefaultText = computed(() => {
    if (!props.setDefaultText) return false
    const content = parsedContent.value
    return !content || content.length < MIN_CONTENT_LENGTH || content === defaultText.value
  })

  const markdownContent = computed(() => {
    return isDefaultText.value ? defaultText.value : parsedContent.value
  })

  const displayContent = computed(() => {
    if (!isTranslated.value || showOriginal.value) {
      return markdownContent.value
    }
    return translatedContent.value
  })

  const canShowTranslation = computed(() => {
    return props.isReadme && TRANSLATION_ENABLED && 
           !isTranslating.value && 
           !isDefaultText.value && 
           markdownContent.value.length >= MIN_CONTENT_LENGTH
  })

  const translateContent = async () => {
    if (!TRANSLATION_ENABLED) {
      ElMessage.warning('翻译功能当前未启用')
      return
    }

    if (!isLoggedIn()) {
      ElMessage.warning('请先登录后再使用翻译功能')
      window.location.href = '/login'
      return
    }

    if (isDefaultText.value) {
      ElMessage.warning('默认文本无需翻译')
      return
    }

    try {
      isTranslating.value = true
      const response = await translate({ content: markdownContent.value })
      
      if (!response) {
        throw new Error('翻译服务无响应')
      }

      if (response.error) {
        throw new Error(response.error)
      }
      
      if (!response.translated_content) {
        throw new Error('翻译服务返回格式错误')
      }

      translatedContent.value = response.translated_content
      isTranslated.value = true
      showOriginal.value = false
    } catch (error) {
      console.error('Translation error:', error)
      const errorMessage = getTranslationErrorMessage(error)
      ElMessage.error(errorMessage)
    } finally {
      isTranslating.value = false
    }
  }

  const getTranslationErrorMessage = (error) => {
    if (!error.message) return '翻译失败，请稍后重试'
    
    if (error.message.match(/timeout|超时|ETIMEDOUT/i)) {
      return '文本内容过长，请尝试分段翻译'
    }

    return error.message
  }

  const toggleTranslation = () => {
    showOriginal.value = !showOriginal.value
  }

  const anchorOptions = {
    tabIndex: false,
  }

  const copyIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M10.7779 5.3335V5.3335C11.9162 5.3335 12.4854 5.3335 12.9395 5.50441C13.6582 5.77492 14.2254 6.34212 14.4959 7.06083C14.6668 7.51495 14.6668 8.08409 14.6668 9.22239V10.4002C14.6668 11.8936 14.6668 12.6404 14.3762 13.2108C14.1205 13.7126 13.7126 14.1205 13.2108 14.3762C12.6404 14.6668 11.8936 14.6668 10.4002 14.6668H9.22239C8.08409 14.6668 7.51495 14.6668 7.06083 14.4959C6.34212 14.2254 5.77492 13.6582 5.50441 12.9395C5.3335 12.4854 5.3335 11.9162 5.3335 10.7779V10.7779M5.60016 10.6668H6.40016C7.89364 10.6668 8.64037 10.6668 9.2108 10.3762C9.71257 10.1205 10.1205 9.71257 10.3762 9.2108C10.6668 8.64037 10.6668 7.89364 10.6668 6.40016V5.60016C10.6668 4.10669 10.6668 3.35995 10.3762 2.78952C10.1205 2.28776 9.71257 1.87981 9.2108 1.62415C8.64037 1.3335 7.89364 1.3335 6.40016 1.3335H5.60016C4.10669 1.3335 3.35995 1.3335 2.78952 1.62415C2.28776 1.87981 1.87981 2.28776 1.62415 2.78952C1.3335 3.35995 1.3335 4.10669 1.3335 5.60016V6.40016C1.3335 7.89364 1.3335 8.64037 1.62415 9.2108C1.87981 9.71257 2.28776 10.1205 2.78952 10.3762C3.35995 10.6668 4.10669 10.6668 5.60016 10.6668Z" stroke="#606266" stroke-linecap="round" stroke-linejoin="round"/></svg>'

  const mdParser = new MarkdownIt({
    html: true,
    highlight: function (str, lang) {
      const escapeHtml = mdParser.utils.escapeHtml

      const copyButtonFragment = `
        <clipboard-copy value="${escapeHtml(str)}"
                        class="copy-button p-1 h-fit hidden group-hover:block hover:bg-[#F0F2F5] rounded cursor-pointer"
        >${copyIcon}</clipboard-copy>`

      if (lang && hljs.getLanguage(lang)) {
        try {
          const highlightedCode = hljs.highlight(str, { language: lang, ignoreIllegals: true }).value
          return `<pre class="group flex space-between w-full !overflow-hidden"><code class="hl-code !leading-6 !overflow-auto w-[calc(100%-24px)]">${highlightedCode}</code>${copyButtonFragment}</pre>`
        } catch (error) {
          console.error(error)
        }
      }

      return `<pre class="group flex space-between w-full !overflow-hidden"><code class="hl-code !leading-6 !overflow-auto w-[calc(100%-24px)]">${escapeHtml(str)}</code> ${copyButtonFragment} </pre>`
    }
  }).use(markdownItAnchor, anchorOptions)

  const initializeCopyButtons = () => {
    document.querySelectorAll('.copy-button').forEach((button) => {
      tippy(button, {
        content: 'Copied!',
        placement: 'left',
        trigger: 'click',
        hideOnClick: false,
        onShow(instance) {
          instance.setProps({ trigger: 'manual' })
          setTimeout(() => {
            instance.hide()
            instance.setProps({ trigger: 'click' })
          }, 2000)
        }
      })
    })
  }

  onMounted(() => {
    initializeCopyButtons()
  })
</script>

<style>
.markdown-body {
  position: relative;
}

.markdown-body :deep(img) {
  max-width: 100% !important;
}

.markdown-body :deep(li) {
  list-style: disc;
}

.translate-button {
  background: rgba(64, 158, 255, 0.1);
  border: none;
  backdrop-filter: blur(8px);
  color: #409EFF;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  height: 32px;
}

.translate-button:hover {
  background: rgba(64, 158, 255, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.translate-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.translate-toggle {
  height: 32px;
  padding: 2px;
  border-radius: 8px;
  background: rgba(64, 158, 255, 0.1);
  backdrop-filter: blur(8px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggle-track {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 140px;
  border-radius: 6px;
  background: white;
}

.toggle-slider {
  position: absolute;
  width: 70px;
  height: calc(100% - 4px);
  background: #409EFF;
  border-radius: 4px;
  transition: transform 0.2s ease;
  left: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.toggle-track .toggle-option {
  position: relative;
  z-index: 1;
  flex: 1;
  text-align: center;
  color: #409EFF;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  user-select: none;
  line-height: 28px;
}

.toggle-track .toggle-option.active {
  filter: none !important;
}

.toggle-track .toggle-option.text-white {
  color: white !important;
}

.translate-toggle:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.translate-button svg {
  flex-shrink: 0;
}
</style>
