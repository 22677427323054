export const tags = {
  computer_vision: "计算机视觉",
  natural_language_processing: "自然语言处理",
  audio_processing: "语音处理",
  multimodal: "多模态",
  other : "其它",
  text_processing: "文本处理",
  graphics: "图像",
  audio: "音频",
  video: "视频",
}