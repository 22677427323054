import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "navigation-tabs" }
const _hoisted_2 = { class: "flex flex-wrap justify-center gap-2 p-4" }
const _hoisted_3 = ["onClick", "onMouseenter"]
const _hoisted_4 = { class: "flex items-center justify-center w-8 h-8" }
const _hoisted_5 = { class: "whitespace-nowrap text-sm font-medium" }
const _hoisted_6 = {
  key: 0,
  class: "ml-2 text-xs px-1.5 py-0.5 rounded-full bg-white/20 min-w-[20px] text-center"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.groupedItems, (group, idx) => {
        return (_openBlock(), _createElementBlock("li", { key: idx }, [
          _createElementVNode("button", {
            class: _normalizeClass(["tab-button relative flex items-center h-12 px-4 rounded-full transition-all duration-300 ease-out overflow-hidden", {
            'bg-gradient-to-r from-blue-500 to-indigo-600 text-white': $setup.isActiveOrHovered(group.appclass),
            'bg-white text-gray-700 hover:bg-gray-100': !$setup.isActiveOrHovered(group.appclass)
          }]),
            onClick: $event => (_ctx.$emit('tab-click', group.appclass)),
            onMouseenter: $event => ($setup.handleMouseEnter(group.appclass)),
            onMouseleave: $setup.handleMouseLeave
          }, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getIcon(group.appclass)), {
                class: _normalizeClass(["w-5 h-5 transition-transform duration-300", {'scale-110': $setup.isActiveOrHovered(group.appclass)}])
              }, null, 8 /* PROPS */, ["class"]))
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-content ml-2 flex items-center transition-all duration-300 ease-out", {'opacity-100 max-w-[200px]': $setup.isActiveOrHovered(group.appclass), 'opacity-0 max-w-0': !$setup.isActiveOrHovered(group.appclass)}])
            }, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(group.appclass), 1 /* TEXT */),
              (group.items.length > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(group.items.length), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}