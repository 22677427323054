import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-[1440px] m-auto flex" }
const _hoisted_2 = { class: "px-[68px] md:px-[16px] pt-[60px] w-full lg:px-[0px]" }
const _hoisted_3 = { class: "px-[32px] md:px-0" }
const _hoisted_4 = { class: "flex flex-col gap-[32px]" }
const _hoisted_5 = { class: "flex justify-between gap-[32px] md:flex-col md:gap-[32px]" }
const _hoisted_6 = { class: "flex flex-col items-start gap-[20px] flex-[1_0_0] self-stretch" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "w-[435px] md:w-full lg:w-[240px]" }
const _hoisted_10 = { class: "flex flex-col gap-[32px]" }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "w-[320px] lg:w-[256px] md:w-full" }
const _hoisted_14 = { class: "mt-[96px] mb-[64px] md:mt-[32px]" }
const _hoisted_15 = { class: "flex flex-col gap-[32px]" }
const _hoisted_16 = { class: "flex gap-[16px] md:justify-between sm:flex-col" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "" }
const _hoisted_20 = { class: "grid grid-cols-3 md:grid-cols-1 gap-x-[32px] gap-y-[46px]" }
const _hoisted_21 = ["href"]
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "w-[320px]" }
const _hoisted_24 = { class: "pt-[20px] border-t border-[#EAECF0] flex justify-center pb-3" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextDetail = _resolveComponent("TextDetail")
  const _component_search = _resolveComponent("search")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-[24px] font-[500] leading-[32px] text-[#101828]" }, "近期热门活动", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("a", {
                href: $data.hot_salons[0].link,
                target: "_blank"
              }, [
                _createElementVNode("img", {
                  src: $data.hot_salons[0].cover,
                  class: "lg:h-[191px] h-[240px] lg:w-[347px] w-[435px] md:w-full md:h-[unset] flex-[1_0_0] rounded-[16px] object-cover"
                }, null, 8 /* PROPS */, _hoisted_8)
              ], 8 /* PROPS */, _hoisted_7),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_TextDetail, {
                  salon: $data.hot_salons[0]
                }, null, 8 /* PROPS */, ["salon"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.hot_salons.slice(-2), (salon) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: salon.id,
                  class: "flex md:flex-col gap-[20px]"
                }, [
                  _createElementVNode("a", {
                    href: salon.link,
                    target: "_blank"
                  }, [
                    _createElementVNode("img", {
                      src: salon.cover,
                      class: "lg:h-[190px] h-[200px] lg:w-[304px] w-[320px] md:w-full md:h-[unset] flex-[1_0_0] rounded-[16px] object-cover"
                    }, null, 8 /* PROPS */, _hoisted_12)
                  ], 8 /* PROPS */, _hoisted_11),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_TextDetail, { salon: salon }, null, 8 /* PROPS */, ["salon"])
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-[24px] font-[500] leading-[32px] text-[#101828]" }, "全部活动", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_16, [
              _createCommentVNode(" search input "),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_el_input, {
                  class: "!md:w-full !w-[320px]",
                  size: "large",
                  modelValue: $data.query.keyword,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.query.keyword) = $event)),
                  placeholder: "搜索活动",
                  clearable: "",
                  "suffix-icon": "el-icon-search",
                  onKeyup: _withKeys($options.fetchItems, ["enter"])
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_el_icon, {
                      onClick: $options.fetchItems,
                      class: "el-input__icon"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_search)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onClick"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "onKeyup"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_el_select, {
                  modelValue: $data.query.city,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.query.city) = $event)),
                  placeholder: "选择城市",
                  class: "!md:w-full !w-[320px]",
                  onChange: $options.fetchItems
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: '全部城市',
                      value: ""
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cities, (city) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: city,
                        label: city,
                        value: city
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "onChange"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_el_select, {
                  modelValue: $data.query.salon_type,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.query.salon_type) = $event)),
                  placeholder: "选择活动类型",
                  class: "!md:w-full !w-[320px]",
                  onChange: $options.fetchItems
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: '全部类型',
                      value: ""
                    }),
                    _createVNode(_component_el_option, {
                      label: "供需对接深度行",
                      value: "event"
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "onChange"])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.salons, (salon) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: salon.id,
                  class: "flex flex-col gap-[20px]"
                }, [
                  _createElementVNode("a", {
                    href: salon.link,
                    target: "_blank"
                  }, [
                    _createElementVNode("img", {
                      src: salon.cover,
                      class: "h-[200px] w-[320px] md:w-full md:h-[unset] flex-[1_0_0] rounded-[16px] object-cover"
                    }, null, 8 /* PROPS */, _hoisted_22)
                  ], 8 /* PROPS */, _hoisted_21),
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_TextDetail, { salon: salon }, null, 8 /* PROPS */, ["salon"])
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_el_pagination, {
            background: "",
            "hide-on-single-page": "",
            "current-page": $data.query.page,
            "onUpdate:currentPage": [
              _cache[3] || (_cache[3] = $event => (($data.query.page) = $event)),
              $options.fetchItems
            ],
            "default-current-page": 1,
            "page-size": 6,
            layout: "prev, pager, next",
            total: Number($data.total_count),
            onCurrentPage: $options.fetchItems
          }, null, 8 /* PROPS */, ["current-page", "total", "onCurrentPage", "onUpdate:currentPage"])
        ])
      ])
    ])
  ]))
}