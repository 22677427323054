<template>
	<div class="main-container w-full">
		<div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
			<div class="editor-container__editor">
				<div ref="editorElement">
					<Ckeditor v-if="isLayoutReady" v-model="config.initialData" :editor="editor" :config="config" @input="inputOnChange" @ready="onEditorReady" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import translations from 'ckeditor5/translations/zh-cn.js';
import Ckeditor from '@ckeditor/ckeditor5-vue';

import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoLink,
	Autosave,
	BalloonToolbar,
	BlockQuote,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	Highlight,
	HorizontalLine,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	Paragraph,
	RemoveFormat,
	SelectAll,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextPartLanguage,
	TextTransformation,
	Underline,
	Undo,
	SimpleUploadAdapter
} from 'ckeditor5';

export default {
	props: {
		content: {
			type: String,
			default: ''
		}
	},	
	data() {
		return {
			isLayoutReady: false,
			config: null, // CKEditor needs the DOM tree before calculating the configuration.
			editor: ClassicEditor
		};
	},
	methods: {
		inputOnChange(event) {
			this.$emit('update:content', event);
		},
		onEditorReady(editor) {
			editor.editing.view.document.on('keydown', (evt, data) => {
				if (data.keyCode === 9 /* Tab */) {
					editor.execute(data.shiftKey ? 'outdent' : 'indent');
					data.preventDefault();
					evt.stop();
				}
			});

			editor.editing.view.document.on('contextmenu', (evt, data) => {
				const selection = editor.model.document.selection;
				
				if (!selection.isCollapsed) {
					editor.plugins.get('BalloonToolbar').show();
				}
				
				data.preventDefault();
				evt.stop();
			});
		}
	},
	watch: {
		content(newValue) {
			this.config.initialData = newValue;
		}
	},
	mounted() {
		this.config = {
			toolbar: {
				items: [
					'fontFamily',
					'fontSize',
					'|',
					'heading',
					'|',
					'bold',
					'italic',
					'underline',
					'|',
					'fontColor',
					'fontBackgroundColor',
					'|',
					'bulletedList',
					'numberedList',
					'outdent',
					'indent',
					'|',
					'alignment',
					'|',
					'link',
					'insertTable',
					'blockQuote',
					'codeBlock',
					'|',
					'undo',
					'redo'
				],
				shouldNotGroupWhenFull: true
			},
			plugins: [
				AccessibilityHelp,
				Alignment,
				Autoformat,
				AutoLink,
				Autosave,
				BalloonToolbar,
				BlockQuote,
				Bold,
				Code,
				CodeBlock,
				Essentials,
				FindAndReplace,
				FontBackgroundColor,
				FontColor,
				FontFamily,
				FontSize,
				Heading,
				Highlight,
				HorizontalLine,
				ImageBlock,
				ImageCaption,
				ImageInline,
				ImageResize,
				ImageStyle,
				ImageTextAlternative,
				ImageToolbar,
				ImageUpload,
				Indent,
				IndentBlock,
				Italic,
				Link,
				LinkImage,
				List,
				Paragraph,
				RemoveFormat,
				SelectAll,
				SpecialCharacters,
				SpecialCharactersArrows,
				SpecialCharactersCurrency,
				SpecialCharactersEssentials,
				SpecialCharactersLatin,
				SpecialCharactersMathematical,
				SpecialCharactersText,
				Strikethrough,
				Table,
				TableCaption,
				TableCellProperties,
				TableColumnResize,
				TableProperties,
				TableToolbar,
				TextPartLanguage,
				TextTransformation,
				Underline,
				Undo,
				SimpleUploadAdapter
			],
			balloonToolbar: {
				items: [
					'fontFamily',
					'fontSize',
					'|',
					'bold',
					'italic',
					'underline',
					'|',
					'fontColor',
					'fontBackgroundColor',
					'|',
					'link',
					'|',
					'bulletedList',
					'numberedList',
					'|',
					'alignment',
					'|',
					'blockQuote'
					],
				shouldNotGroupWhenFull: true
			},
			fontFamily: {
				options: [
					'default',
					'Arial, Helvetica, sans-serif',
					'Courier New, Courier, monospace',
					'Georgia, serif',
					'Lucida Sans Unicode, Lucida Grande, sans-serif',
					'Tahoma, Geneva, sans-serif',
					'Times New Roman, Times, serif',
					'Trebuchet MS, Helvetica, sans-serif',
					'Verdana, Geneva, sans-serif',
					'微软雅黑, Microsoft YaHei',
					'宋体, SimSun',
					'黑体, SimHei',
					'楷体, KaiTi'
				],
				supportAllValues: true
			},
			fontSize: {
				options: [
					8,
					10,
					12,
					14,
					'default',
					18,
					20,
					22,
					24,
					26,
				],
				supportAllValues: true
			},
			heading: {
				options: [
					{
						model: 'paragraph',
						title: 'Paragraph',
						class: 'ck-heading_paragraph'
					},
					// {
					// 	model: 'heading1',
					// 	view: 'h1',
					// 	title: 'Heading 1',
					// 	class: 'ck-heading_heading1'
					// },
					{
						model: 'heading2',
						view: 'h2',
						title: 'Heading 2',
						class: 'ck-heading_heading2'
					},
					{
						model: 'heading3',
						view: 'h3',
						title: 'Heading 3',
						class: 'ck-heading_heading3'
					},
					{
						model: 'heading4',
						view: 'h4',
						title: 'Heading 4',
						class: 'ck-heading_heading4'
					},
					{
						model: 'heading5',
						view: 'h5',
						title: 'Heading 5',
						class: 'ck-heading_heading5'
					},
					// {
					// 	model: 'heading6',
					// 	view: 'h6',
					// 	title: 'Heading 6',
					// 	class: 'ck-heading_heading6'
					// }
				]
			},
			image: {
				toolbar: [
					'toggleImageCaption',
					'imageTextAlternative',
					'|',
					'imageStyle:inline',
					'imageStyle:wrapText',
					'imageStyle:breakText',
					'|',
					'resizeImage'
				]
			},
			initialData: this.content || "",
			language: 'zh-cn',
			link: {
				addTargetToExternalLinks: true,
				defaultProtocol: 'https://',
				decorators: {
					toggleDownloadable: {
						mode: 'manual',
						label: 'Downloadable',
						attributes: {
							download: 'file'
						}
					}
				}
			},
			menuBar: {
				isVisible: true
			},
			placeholder: 'Type or paste your content here!',
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
			},
			simpleUpload: {
				uploadUrl: '/internal_api/upload',
				headers: {
					'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				}
			},
			translations: [translations],
			keystrokes: [
				[ 'Tab', 'indent' ],
				[ 'Shift+Tab', 'outdent' ]
			],
			indentBlock: {
				offset: 40,
				unit: 'px'
			},
			contextMenu: true
		};

		this.isLayoutReady = true;
	}
};
</script>

<style scoped>
:deep(.ck-balloon-panel) {
	border-radius: 8px !important;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

:deep(.ck-balloon-panel .ck-toolbar) {
	border: none !important;
	border-radius: 8px !important;
	background: #ffffff !important;
}

:deep(.ck-balloon-panel .ck-toolbar__items) {
	padding: 4px !important;
}

:deep(.ck-balloon-panel .ck-button) {
	border-radius: 4px !important;
}

:deep(.ck-dropdown__panel) {
	max-height: 300px !important;
	overflow-y: auto !important;
}

:deep(.ck-dropdown__panel .ck-list) {
	padding: 4px !important;
}

:deep(.ck-dropdown__panel .ck-list__item) {
	padding: 4px 8px !important;
	border-radius: 4px !important;
}

:deep(.ck-dropdown__panel .ck-list__item:hover) {
	background: #f5f5f5 !important;
}

:deep(.ck-dropdown__panel .ck-list__item .ck-button) {
	padding: 4px 8px !important;
	width: 100% !important;
	text-align: left !important;
}
</style>
