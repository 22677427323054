<template>
  <div class="bg-white rounded-lg border border-[#EAECF0] overflow-hidden hover:shadow-md transition-shadow duration-200">
    <div class="p-6">
      <div class="mb-4">
        <h3 class="text-lg font-medium text-gray-900 truncate">
          <a :href="'/event_conclusions/' + conclusion.id" class="hover:text-blue-600">{{ conclusion.title }}</a>
        </h3>
      </div>
      
      <div class="space-y-2">
        <div class="text-sm text-gray-500 flex items-center">
          <el-icon class="mr-2"><OfficeBuilding /></el-icon>
          <span class="truncate">{{ conclusion.event_leader }}</span>
        </div>
        <div class="text-sm text-gray-500 flex items-center">
          <el-icon class="mr-2"><Location /></el-icon>
          <span>{{ conclusion.event_location }}</span>
          <span class="mx-2 text-gray-300">|</span>
          <el-icon class="mr-2"><Calendar /></el-icon>
          <span>{{ formatDate(conclusion.event_date) }}</span>
        </div>
      </div>

      <div class="mt-4 pt-3 border-t border-[#EAECF0] flex justify-between items-center">
        <a :href="'/event_conclusions/' + conclusion.id" 
            class="inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200">
            <span>查看详情</span>
            <el-icon class="ml-1.5 text-[16px]"><ArrowRight /></el-icon>
        </a>
        <a :href="'/event_conclusions/' + conclusion.id + '/edit'"
            class="inline-flex items-center text-[14px] text-[#475467] hover:text-blue-600 transition-colors duration-200">
            <el-icon class="mr-1.5 text-[16px]"><Edit /></el-icon>
            <span>编辑</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowRight, OfficeBuilding, Calendar, Location } from '@element-plus/icons-vue'

const props = defineProps({
  conclusion: {
    type: Object,
    required: true
  }
})

const formatDate = (date) => {
  if (!date) return ''
  const d = new Date(date)
  return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
}
</script> 